/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: Content.js
 * Description: The content box is a box for content on the
 * site. It is linked up with the API to create editable box-
 * es that can be placed throughout.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ReactMarkdown from "markdown-to-jsx";
import Header from "../Header";
import AppContext from "../../helpers/AppContext";
import EditIcon from "@material-ui/icons/Edit";
import EditContentDialog from "./EditContentDialog";
import { markdownOptions } from "./markdownOptions";
import fetchContent from "helpers/fetchContent";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10em",
  },
}));

function AdminEdit(props) {
  const { ...otherProps } = props;
  const { userPerms } = useContext(AppContext);
  if (userPerms.admin) {
    return (
      <IconButton color="secondary" {...otherProps}>
        <EditIcon />
      </IconButton>
    );
  } else {
    return null;
  }
}

export default function Content(props) {
  const initContent = {
    title: "",
    mainText: "",
  };
  const { id, header = "h3", ...otherProps } = props;
  const { content, setContent } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [thisContent, setThisContent] = useState(initContent);
  const [editDialog, setEditDialog] = useState(false);
  const classes = useStyles();

  const handleEditClick = () => {
    setEditDialog(true);
  };

  const closeEditDialog = () => {
    setEditDialog(false);
  };

  useEffect(() => {
    if (content[id]) {
      setThisContent(content[id]);
      setLoading(false);
    } else {
      fetchContent(id)
        .then((result) => {
          setThisContent(result);
          setLoading(false);
          setContent({
            ...content,
            id: result,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress size={64} />
        </Box>
      ) : (
        <div>
          <EditContentDialog
            id={id}
            open={editDialog}
            onClose={closeEditDialog}
            content={thisContent}
            setContent={setThisContent}
          />
          <Box p={2} className={classes.root} {...otherProps}>
            <AdminEdit
              className={classes.editButton}
              onClick={handleEditClick}
            />
            <Header variant={header} pl={0} pt={0} text={thisContent.title} />
            <ReactMarkdown options={markdownOptions}>
              {thisContent.mainText}
            </ReactMarkdown>
          </Box>
        </div>
      )}
    </div>
  );
}
