/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: CreateMachineGroupDialog.js
 * Description: Simple create Dialog for creating different
 * Machine Groups
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Hayley Zorkic
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    Grid,
    DialogActions,
    makeStyles,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';

import { useContext, useEffect, useState } from 'react';
import { Header } from 'components';
import { createMachineGroup, updateMachineGroup } from 'api/mutations';
import { API } from 'aws-amplify';
import AppContext from 'helpers/AppContext';
import { getMachineGroup, listMachinesByGroup } from 'api/queries';
import Machines from './Machines';
import { createMachineGroupTransaction, updateMachineGroupTransaction } from 'helpers/transactions';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    content: {
        height: 'auto',
        display: 'flex',
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(2),
    },
    machineGroupName: {
        width: '45%',
        minWidth: 180,
    },
    machineGroupStatus: {
        width: '20%',
        minWidth: 100,
    },
    machineGroupDescription: {
        minWidth: '95%',
    },
    machineGroupHour: {
        minWidth: '90%',
        width: '15%',
    }
}))

const MACHINE_TYPES = [
    "PRINTER",
    "WORKSHOP",
    "LASER",
    "TOOLKIT"
]

async function createNewMachineGroup(inputvars) {
    let request = {
        query: createMachineGroup,
        variables: {
            input: inputvars
        }
    }

    let response = await API.graphql(request)
    return response.data.createMachineGroup
}

async function updateGroup(inputvars) {
    let request = {
        query: updateMachineGroup,
        variables: {
            input: inputvars
        }
    }

    let response = await API.graphql(request)
    return response.data.updateMachineGroup
}

async function getMachineGroupDetails(id) {
    let request = {
        query: getMachineGroup,
        variables: {
            id: id
        }
    }

    let response = await API.graphql(request)
    return response.data.getMachineGroup
}

export default function CreateMachineGroupDialog(props) {
    const {open, onClose, machineGroupId, task, facilities} = props
    const initMachineGroup = {
        name: '',
        slotDuration: '30',
        requiredTrainings: '',
        reservable: 1,
        machineGroup: '',
        machineType: '',
        description: '',
        areaIndex: 0,
        typeIndex: 0
    }

    const [loading, setLoading] = useState(true);
    const classes = useStyles()
    const { setErrState, loggedInMember } = useContext(AppContext)
    const [ machineGroup, setMachineGroup ] = useState(initMachineGroup);
    const [ title, setTitle ] = useState("")

    useEffect(() => {
        if (open) {
            if (task === 'manage') {
                setLoading(true);
                getMachineGroupDetails(machineGroupId).then((result) => {
                    result.areaIndex = facilities.areas.findIndex((el) => el.id === result.areaId)
                    result.typeIndex = MACHINE_TYPES.indexOf(result.type)
                    setMachineGroup(result)
                    setTitle(result.name)
                    setLoading(false)
                })
            } else {
                setTitle("New Tool Group")
            }
        } else {
            
            setLoading(false);
        }
    }, [open, task, machineGroupId])

    const handleClose = () => {
        setMachineGroup(initMachineGroup);
        setTitle("");
        onClose();
    }

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === 'reservable') {
            let res = machineGroup.reservable ? 0 : 1;
            setMachineGroup({
                ...machineGroup,
                [name]: res
            })
        } else {
            setMachineGroup({
                ...machineGroup,
                [name]: value,
            })
        }
    }

    const handleSubmit = () => {
        let inputVars = {
            name: machineGroup.name,
            description: machineGroup.description,
            requiredTrainings: machineGroup.requiredTrainings,
            reservable: machineGroup.reservable,
            type: MACHINE_TYPES[machineGroup.typeIndex],
            areaId: facilities.areas[machineGroup.areaIndex].id,
            slotDuration: parseInt(machineGroup.slotDuration) * 60
        }

        if (task === "manage") {
            inputVars = {
                ...inputVars,
                id: machineGroupId
            }
            updateGroup(inputVars).then((r) => {
                setErrState({
                    severity: 'success',
                    open: true,
                    text: `Successfully updated ${r.name}`
                })
                updateMachineGroupTransaction(loggedInMember.eid, r.id, machineGroup.name)
                .catch((err) => {
                    console.log("Could not make transaction: ", err.message)
                })
                handleClose();
            }).catch((err) => {
                setErrState({
                    severity: 'error',
                    open: true,
                    text: `Was not able to update ${machineGroup.name}`
                })
            })
        } else {
            createNewMachineGroup(inputVars).then((r) => {
                setErrState({
                    severity: 'success',
                    open: true,
                    text: `Successfully created Machine Group: ${machineGroup.name}`
                })
                createMachineGroupTransaction(loggedInMember.eid, r.id, machineGroup.name)
                .catch((err) => {
                    console.log("Could not make transaction: ", err.message)
                })
                handleClose()
            }).catch((err) => {
                setErrState({
                    severity: "error",
                    open: true,
                    text: `Unable to create MachineGroup: ${err.message}`
                })
                console.log(err)
            })
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="xl"
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle>
                <Header variant='h3' p={0} text={title}/>
            </DialogTitle>

            <DialogContent>
                {loading ? <CircularProgress size={64} /> :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <form className={classes.root}>
                            <FormControl className={classes.machineGroupName}>
                                <TextField
                                    id="machine-group-name"
                                    label="Machine Group Name"
                                    value={machineGroup.name}
                                    inputProps={{
                                        name: 'name'
                                    }}
                                    required
                                    onInput={handleChange}
                                />
                            </FormControl>
                            <TextField
                                className={classes.machineGroupDescription}
                                id="machine-group-description"
                                label="Description"
                                value={machineGroup.description}
                                inputProps={{
                                    name: 'description'
                                }}
                                // helperText="This description will be displayed in the appropriate area. This field accepts markdown."
                                multiline
                                onInput={handleChange}
                                rows={8}
                            />
                        </form>
                        {task === 'manage' ? <Machines groupId={machineGroupId} groupType={machineGroup.machineType} /> : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Header variant='h5' p={0} text="Area" />
                        <FormControl fullWidth>
                            <Select
                                value={machineGroup.areaIndex}
                                inputProps={{
                                    name: "areaIndex"
                                }}
                                onChange={handleChange}
                            >
                                {facilities.areas.map((area, id) => {
                                    return (
                                        <MenuItem value={id} key={`area-list-${id}`}>{area.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="machine-type">Machine Type</InputLabel>
                            <Select
                                label-id="machine-type"
                                value={machineGroup.typeIndex}
                                inputProps={{
                                    name: "typeIndex"
                                }}
                                onChange={handleChange}
                            >
                                {MACHINE_TYPES.map((machine, id) => {
                                    return (
                                        <MenuItem value={id} key={`machine-type-list-${id}`}>{machine}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <Header variant='h5' p={0} pt={2} text="Reservations"/>
                        <FormGroup>
                            <FormControlLabel 
                                id="machine-group-reservable"
                                label="Reservable?"
                                // helperText="Marks if machines are able to be reserved."
                                color="primary"
                                control={
                                    <Checkbox 
                                        checked={machineGroup.reservable} 
                                        inputProps={{
                                            name: 'reservable'
                                        }}
                                        onClick={handleChange}
                                    />}
                            />
                            <TextField 
                                className={classes.machineGroupHour}
                                variant="outlined"
                                label="Reservation Slot Length"
                                // helperText="Time for a reservation in minutes"
                                size="small"
                                inputProps={{
                                    name: 'slotDuration'
                                }}
                                value={machineGroup.slotDuration}
                                disabled={machineGroup.reservable === 0}/>
                        </FormGroup>
                        <Header variant='h5' p={0} pt={2} text="Materials" />
                        
                    </Grid>
                </Grid> 
                }                   
            </DialogContent>

            <DialogActions>
                <Box>
                    <ButtonGroup color="primary" variant="contained">
                        <Button onClick={handleSubmit}>
                            {
                                task === 'manage' ? "Update Machine Group" : "Create Machine Group"
                            }
                            {/* Create Machine Group */}
                        </Button>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogActions>
        </Dialog>


    )


}
