/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: signOut.js
 * Description: Simple helper function to call Auth.signOut()
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Amplify, { Auth } from 'aws-amplify';

async function signOut(setUserPerms) {
    return await Auth.signOut().then(() => {
        setUserPerms({});
        Auth.currentCredentials().then((credentials) => {
        Amplify.configure({
            aws_appsync_authenticationType: credentials.authenticated ?
            "AMAZON_COGNITO_USER_POOLS" :
            "AWS_IAM"
        })
    })
    });     
}

export default signOut;