/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: LandingPage.js
 * Route: https://texasinventionworks.com/
 * Description: This is the landing page of the portal
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext } from 'react';
import {
    Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Content } from '../../components';
import HomeHeader from './components/HomeHeader';
import AppContext from '../../helpers/AppContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    pageTitle: {
        alignText: 'center',
    },
    paperPromo: {
        height: "25em",
        width: "100%"
    },
    paperContent: {
        // width: "100%",
  },
}));

export default function LearnMore(props) {
    const classes = useStyles();
    const { content } = useContext(AppContext);
    const { match } = props
    
    let id = content["slugs"][match.params.title];

    return (
        <div className={classes.root}>
            <HomeHeader />
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Content pl={0} id={id} />
                </Grid>
            </Grid>
        </div>
    );
};

