/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: NavBar.js
 * Description: Simple NavBar. LoginDialog and MenuDrawer are
 * codependent on this module. State and context are passed
 * from the nav bar to both of these modules.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button,
    Toolbar,
    AppBar,
    IconButton,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import LoginDialog from './LoginDialog';
import signOut from '../../helpers/SignOut';
import AppContext from '../../helpers/AppContext';
import { Auth } from 'aws-amplify';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ( {
    root: {
      display: 'flex',
      borderLeft: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "0px 4px 2px -2px rgba(0,0,0,0.2)",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderLeft: 0,
      boxShadow: "0px 4px 2px -2px rgba(0,0,0,0.2)",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        padding: 0,
        fontFamily: "'Barlow Condensed', 'Roboto'",
        fontWeight: 600,
        letterSpacing: ".125em",
        textTransform: "uppercase"
    },
    hide: {
      display: 'none',
    }
}));

function UserButton() {
    const [loginOpen, setLoginOpen] = useState(false);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const buttonSize = isSmallScreen ? "small" : "large";

    return ( 
      <AppContext.Consumer>
          {
            ({authenticated, toggleAuthenticated, setUserPerms, setLoggedInMember}) => {
              const handleLogoutClick = () => {
                  signOut(setUserPerms).then((result) => {
                    toggleAuthenticated();
                  }).catch((err) => {
                    console.log("Error signing out!", err);
                  });
              };

              const handleLoginClick = () => {
                  setLoginOpen(true);
              };

              const handleLoginClose = () => {
                  setLoginOpen(false);
                  Auth.currentAuthenticatedUser().then((result) => {
                    toggleAuthenticated();
                  }).catch((err) => {
                    if (err !== "The user is not authenticated")
                      console.log("Unable to log in: ", err);
                  })
              };

              if (authenticated) {
                return (
                  <div>
                    <Link style={{textDecoration: 'none'}} to='/'>
                      <Button variant="contained" color="primary" size={ buttonSize } onClick={handleLogoutClick}>
                        Logout
                      </Button>
                    </Link>
                  </div>
                ) 
              };
              return (
              <div>
                  <Button variant="contained" color="primary" size={ buttonSize } onClick={handleLoginClick}>
                      Login
                  </Button>
                  <LoginDialog 
                      open={loginOpen} 
                      onClose={handleLoginClose} 
                  />
              </div>
            )}
          }
        
        </AppContext.Consumer>
      )
}

function ResponsiveTitle({ width }) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const titleProps = {
    text: isSmallScreen ? "TIW" : "Texas Inventionworks",
  };

  return (
    <Typography variant="h3" className={classes.title}>
      {titleProps.text }
    </Typography>
  )
}

export default function NavBar() {
    const classes = useStyles();
    const {drawerOpen} = useContext(AppContext);

    return (
      <div>
        <AppBar 
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: drawerOpen,
          })}
        >
        <Toolbar>
          <AppContext.Consumer>
            {
              ({authenticated, drawerOpen, toggleDrawer}) => {
                if (authenticated) {
                  return (
                    <IconButton 
                      edge="start" 
                      className={clsx(classes.menuButton, {
                        [classes.hide]: drawerOpen,
                      })} 
                      color="inherit" 
                      aria-label="menu-open-drawer"
                      onClick={toggleDrawer}
                    >
                      <MenuIcon fontSize='large'/>
                    </IconButton>
                  )
                }
              }
            }
          </AppContext.Consumer>
          <ResponsiveTitle />
          <UserButton />
        </Toolbar>
      </AppBar>
      {/* <MenuDrawer /> */}
      </div>
    );
}