/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: LandingPage.js
 * Route: https://texasinventionworks.com/
 * Description: This is the landing page of the portal
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import {
    Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Content, Preview } from '../../components';
import HomeHeader from './components/HomeHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    pageTitle: {
        alignText: 'center',
    },
    paperPromo: {
        height: "25em",
        width: "100%"
    },
    paperContent: {
        // width: "100%",
  },
}));

function LandingPage() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <HomeHeader />
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Content pl={0} id='landing-page-main-content' />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Preview id="landing-page-preview-1" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Preview id="landing-page-preview-2" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Preview id="landing-page-preview-3" />
                </Grid>
            </Grid>
        </div>
    );
};


export default LandingPage;