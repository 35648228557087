/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: createAreaDialog.js
 * Description: Simple create Dialog for creating different
 * Areas
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence, Hakeem Telsiz
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    Grid,
    DialogActions,
    makeStyles,
} from '@material-ui/core';
import { API } from 'aws-amplify';
import { useEffect, useState, useContext  } from 'react';
import AppContext from 'helpers/AppContext';
import { createArea } from 'api/mutations';
import { Header } from 'components';
import AreaHours from './AreaHours';
import AreaDescription from './AreaDescription';
import { createAreaTransaction } from 'helpers/transactions';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }, 
    formControl: {
        margin: theme.spacing(2),
    },
    areaName: {
        minWidth: '50%',
    },
    areaRoom: {
        minWidth: '20%',
    },
    areaDescription: {
        minWidth: '95%',
    },

}))

async function createNewArea(inputVars) {
    let request = {
        query: createArea,
        variables: {
            input: inputVars
        }
    }

    let response = await API.graphql(request)
    return response.data.createArea;
}

export default function NewAreaDialog(props) {
    const initArea = {
        name: "",
        description: "",
        roomNumber: "",
        hours: [
            {
                day: "MONDAY",
                open: 0,
                openingTime: '12',
                closingTime: '5'
            },
            {
                day: "TUESDAY",
                open: 0,
                openingTime: '12',
                closingTime: '5'
            },
            {
                day: "WEDNESDAY",
                open: 0,
                openingTime: '12',
                closingTime: '5'
            },
            {
                day: "THURSDAY",
                open: 0,
                openingTime: '12',
                closingTime: '5'
            },
            {
                day: "FRIDAY",
                open: 0,
                openingTime: '12',
                closingTime: '5'
            },
            {
                day: "SATURDAY",
                open: 0,
                openingTime: '12',
                closingTime: '5'
            },
            {
                day: "SUNDAY",
                open: 0,
                openingTime: '12',
                closingTime: '5'
            },
        ]
    }
    const { open, onClose } = props
    const { setErrState, loggedInMember } = useContext(AppContext);
    const classes = useStyles()
    const [ area, setArea ] = useState(initArea);
    const [ valid, setValid ] = useState(false);

    const handleClose = () => {
        setArea(initArea);
        onClose();
    }

    useEffect(() => {
        setValid(area.name !== "" && area.description !== "" && area.roomNumber !== "");
    }, [area])
    
    const handleSubmit = () => {
        createNewArea(area).then((result) => {
            setErrState({
                severity: 'success',
                open: true,
                message: `Successfully Created Area: ${area.name}`
            })
            createAreaTransaction(loggedInMember.eid, result.id, area.name).catch((err) => {
                console.log("Cound not create transaction: ", err.message)
            })
            handleClose();
        }).catch((err) => {
            setErrState({
                severity: 'error',
                open: true,
                message: `That's gonna be a naw from me dawg. Can't create area: ${err.message}`
            })
            console.log(err)
        })
    }

    return(
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle>
                <Header variant='h4' p={0} text="New Area" />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <AreaDescription classes={classes} area={area} setArea={setArea} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AreaHours classes={classes} area={area} setArea={setArea} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box>
                    <ButtonGroup color="primary" variant='contained'>
                        <Button disabled={!valid} onClick={handleSubmit}>
                            Create Area
                        </Button>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogActions>
        </Dialog>
    )
}