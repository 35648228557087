/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: Admin.js
 * Route: https://texasinventionworks.com/staff/members/
 * Description: This page allows for staff to view Site 
 * Analytics and Dashbboards.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Hayley Zorkic, Davin Lawrence, Hakeem Telsiz
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    Grid,
    makeStyles,
    Tab,
    Tabs,
    AppBar
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { PageHeader} from 'components';
import Facilities from './Facilities/Facilities';
import Trainings from './Trainings/Trainings';
import { 
    TabContext, 
    TabPanel 
} from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    root: {

    },
    paper: {
        display: 'flex',
        height: '50em',
    },
    box: {
        display: 'flex',
        width: '100%',
    },
    toolbar: {
        padding: theme.spacing(1),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1),
    },
    buttons: {
        margin: theme.spacing(1),
    },
    eidBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    eidButton: {
        margin: 'auto',
    },
    eidNumber: {
        margin: 'auto',
    },
    paperBox: {
        width: '100%'
    }
}))


export default function Admin() {
    const classes = useStyles();
    const [current, setCurrent] = useState('');
    const [value, setValue] = useState('1');

    const handleTab = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        setCurrent('facilities')
    }, [])

    return (
        <div>
            <Grid container maxWidth='lg' spacing={2}>
                <Grid item xs={12}>
                    <PageHeader text="Admin" />
                </Grid>
                <Grid item xs={12} p={4}>
                    <AppBar position="static">
                        <Tabs 
                            value={value} 
                            onChange={handleTab} 
                            variant="fullWidth"
                            centered
                        >
                            <Tab label="Facilities" value="1" />
                            <Tab label="Trainings" value="2" />
                            {/* <Tab label="Staff" value="3" />
                            <Tab label="Analytics" value="4" /> */}
                        </Tabs>
                    </AppBar>
                </Grid>
            </Grid>
            <TabContext value={value}>
                <TabPanel value="1">
                    <Facilities />
                </TabPanel>
                <TabPanel value="2">
                    <Trainings />
                </TabPanel>
            </TabContext>
        </div>
    )
}