/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d3ba986c-642c-407f-8a3f-3459a71e16b3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_V9otzQhCc",
    "aws_user_pools_web_client_id": "33j3ktlu66a5p1230f8qdbqurk",
    "oauth": {
        "domain": "texasinventionworks-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://texasinventionworks.com/",
        "redirectSignOut": "https://texasinventionworks.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://scpqcuphhrfznjadmanqayh7qi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-k6efmnt5h5h25gc6apr6ft3qge",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ubqzc5qsji.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "canvasApi",
            "endpoint": "https://zcyb7d9dcl.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "portal-prints165204-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "classIncrement-dev",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "0dab8ee22feb4d51a29d05924f74e773",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "0dab8ee22feb4d51a29d05924f74e773",
            "region": "us-east-1"
        }
    }
};


export default awsmobile;
