import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { API } from "aws-amplify";
import { Header } from "components";
import { useContext, useEffect, useState } from "react";

import { listMachinesByGroup } from "api/queries";
import { createMachine, updateMachine, deleteMachine } from "api/mutations";
import AppContext from "helpers/AppContext";
import {
  createMachineTransaction,
  updateMachineTransaction,
} from "helpers/transactions";
import { parseToolName } from "helpers";
import Confirmation from "components/Confirmation";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  machineName: {
    paddingRight: theme.spacing(1),
    width: "15em",
  },
  machineStatus: {
    paddingRight: theme.spacing(1),
    width: "12em",
  },
  toolbarButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(2),
  },
  searchField: {
    marginLeft: "auto",
  },
  editStatusBox: {
    width: "100%",
    height: "auto",
    marginTop: theme.spacing(1),
  },
  editStatusField: {
    width: "100%",
    height: "20em",
  },
  machineBox: {
    display: "flex",
    width: "100%",
    height: "30em",
  },
}));

const MACHINESTATUS = ["ACTIVE", "CHECKEDIN", "CHECKEDOUT", "REPAIR"];

async function getMachines(id) {
  let request = {
    query: listMachinesByGroup,
    variables: {
      machineGroupId: id,
    },
  };

  let response = await API.graphql(request);
  return response.data.listMachinesByGroup.items;
}

async function createNewMachine(inputVars) {
  let request = {
    query: createMachine,
    variables: {
      input: {
        ...inputVars,
        currentHolderId: "tiw",
      },
    },
  };

  let response = await API.graphql(request);
  return response.data.createMachine;
}

async function modifyMachine(inputVars) {
  let request = {
    query: updateMachine,
    variables: {
      input: inputVars,
    },
  };

  let response = await API.graphql(request);
  return response.data.updateMachine;
}

async function removeMachine(inputVars) {
  let request = {
    query: deleteMachine,
    variables: {
      input: inputVars,
    }
  }
  console.log(inputVars)

  let response = await API.graphql(request);
  return response.data.deleteMachine;
}

function StatusEditInput(props) {
  const { id, api, value, field } = props;
  const [currValue, setCurrValue] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setCurrValue(MACHINESTATUS.indexOf(value));
  }, [value]);

  const handleChange = (e) => {
    let val = e.target.value;
    console.log(val);
    setCurrValue(val);
    api.setEditCellValue({ id, field, value: MACHINESTATUS[val] });
  };

  return (
    <Box className={classes.editStatusBox}>
      <TextField
        className={classes.editStatusField}
        size="medium"
        select
        value={currValue}
        inputProps={{
          name: "statusIndex",
        }}
        onChange={handleChange}
      >
        {MACHINESTATUS.map((status, id) => {
          return <MenuItem value={id}>{status}</MenuItem>;
        })}
      </TextField>
    </Box>
  );
}

function RowMenuCell(props) {
  const { api, id } = props;
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmItem, setConfirmItem] = useState('')
  const { setErrState, loggedInMember } = useContext(AppContext);
  const classes = useStyles();
  const isInEditMode = api.getRowMode(id) === "edit";

  const handleEditClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, "edit");
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    api.commitRowChange(id);
    let row = api.getRow(id);
    console.log(row);

    setLoading(true);
    let inputVars = {
      id: id,
      name: parseToolName(row.name),
    };
    modifyMachine(inputVars)
      .then((result) => {
        setErrState({
          severity: "success",
          text: `Updated ${result.name}`,
          open: true,
        });
        updateMachineTransaction(
          loggedInMember.eid,
          result.id,
          result.name
        ).catch((err) => {
          console.log("Could not create transaction: ", err.message);
        });
        api.setRowMode(id, "view");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    let row = api.getRow(id);
    setConfirmItem(row)
    setConfirmOpen(true);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, "view");
  };

  const handleDeleteConfirmation = () => {
    let id = confirmItem.id
    let inputVars = {
      id: id
    }
    setLoading(true);
    removeMachine(inputVars).then((result) => {
      api.updateRows([{id: id, _action: 'delete'}]);
      setErrState({
        severity: "success",
        text: `Removed ${result.name}`,
        open: true,
      })
    }).catch((err) => {
      setErrState({
        severity: "error",
        text: `Unable to Remove item: ${err.message}`,
        open: true,
      })
    })
    setLoading(true);
  }

  if (isInEditMode) {
    return (
      <div className={classes.root}>
        {loading ? (
          <CircularProgress />
        ) : (
          <IconButton
            color="primary"
            size="small"
            aria-label="save"
            onClick={handleSaveClick}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
        )}
        <IconButton
          color="inherit"
          size="small"
          aria-label="cancel"
          className={classes.textPrimary}
          onClick={handleCancelClick}
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Confirmation 
        open={confirmOpen}
        handleClose={() => {setConfirmOpen(false)}}
        handleConfirm={handleDeleteConfirmation}
        item={confirmItem}
      />
      <IconButton
        color="inherit"
        size="small"
        aria-label="edit"
        onClick={handleEditClick}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton
        color="inherit"
        size="small"
        aria-label="edit"
        onClick={handleDeleteClick}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

function EditToolbar(props) {
  const {
    groupId,
    groupType,
    refresh,
    setRefresh,
    searchText,
    onSearchChange,
    clearSearch,
  } = props;
  const { setErrState, loggedInMember } = useContext(AppContext);
  const initData = {
    name: "",
    statusIndex: 0,
    status: "ACTIVE",
  };
  const [add, setAdd] = useState(false);
  const [data, setData] = useState(initData);
  const classes = useStyles();

  const handleAdd = () => {
    setAdd(true);
  };

  const handleSave = () => {
    let inputVars = {
      id: data.name,
      name: data.name,
      status: MACHINESTATUS[data.statusIndex],
      machineGroupId: groupId,
      type: groupType,
    };
    if (data.name === "") {
      setErrState({
        severity: "error",
        open: true,
        text: "Machine Name Cannot Be Empty!",
      });
      return;
    }

    createNewMachine(inputVars)
      .then((result) => {
        createMachineTransaction(
          loggedInMember.eid,
          result.id,
          data.name
        ).catch((err) => {
          console.log("Could not create transaction: ", err.message);
        });
        setRefresh(refresh ? 0 : 1);
        setAdd(false);
        setData(initData);
      })
      .catch((err) => {
        console.log(err);
        setErrState({
          severity: "error",
          open: true,
          text: "Unable to create machine.",
        });
      });
  };

  const handleCancel = () => {
    setAdd(false);
    setData(initData);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "name") {
      value = parseToolName(value);
    }
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <GridToolbarContainer className={classes.toolbar}>
      {add ? (
        <div>
          <FormControl className={classes.machineName}>
            <TextField
              id="machine-name"
              label="Name"
              value={data.name}
              inputProps={{
                name: "name",
              }}
              size="small"
              variant="outlined"
              onInput={handleChange}
            />
          </FormControl>
          <FormControl className={classes.machineStatus}>
            <TextField
              select
              variant="outlined"
              label="Status"
              value={data.statusIndex}
              inputProps={{
                name: "statusIndex",
              }}
              size="small"
              onChange={handleChange}
            >
              {MACHINESTATUS.map((status, id) => {
                return <MenuItem value={id}>{status}</MenuItem>;
              })}
            </TextField>
          </FormControl>
        </div>
      ) : null}
      <Button
        color="primary"
        size="small"
        startIcon={add ? <SaveIcon /> : <AddIcon />}
        onClick={add ? handleSave : handleAdd}
        className={classes.toolbarButton}
        variant="contained"
      >
        {add ? "Save Machine" : "Add Machine"}
      </Button>
      {add ? (
        <Button
          className={classes.toolbarButton}
          color="primary"
          size="small"
          startIcon={<CancelIcon />}
          onClick={handleCancel}
          variant="contained"
        >
          Cancel
        </Button>
      ) : null}
      <TextField
        className={classes.searchField}
        variant="standard"
        placeholder="Search..."
        value={searchText}
        autoFocus
        onChange={onSearchChange}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              size="small"
              style={{ visibility: searchText ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </GridToolbarContainer>
  );
}

function renderStatusEditInput(params) {
  return <StatusEditInput {...params} />;
}

export default function Machines(props) {
  const { groupId } = props;
  const { setErrState, loggedInMember } = useContext(AppContext);
  const classes = useStyles();
  const [machines, setMachines] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    setRows([]);
    getMachines(groupId)
      .then((result) => {
        setMachines(result);
        setRows(result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrState({
          severity: "error",
          open: true,
          text: "Unable to retrieve machines for this group.",
        });
        console.log(err);
      });
  }, [groupId, refresh]);

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = machines.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field] != null) {
          return searchRegex.test(row[field].toString());
        } else {
          return null;
        }
      });
    });
    setRows(filteredRows);
  };

  const columns = [
    // {field: 'id', headerName: "ID", flex: 1},
    { field: "name", headerName: "Name", editable: "true", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      renderEditCell: renderStatusEditInput,
      editable: "true",
      flex: 1,
    },
    { field: "updatedAt", headerName: "Last Update", flex: 1, type: "date" },
    {
      field: "actions",
      headerName: "Edit",
      renderCell: RowMenuCell,
      sortable: false,
      flex: 0.5,
      headerAlign: "center",
      filterable: false,
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: "deleted",
      hide: true,
    }
  ];

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
    console.log(event);
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
    console.log(event);
  };

  return (
    <Box>
      <Header variant="h4" pt={2} pb={2} text="Machines" />
      <Box className={classes.machineBox}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            filter: {
              filterModel: {
                items: [{columnField: 'deleted', operatorValue: '!=', value: true}]
              }
            }
          }}
          editMode="row"
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          pageSize={50}
          loading={loading}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: {
              groupId,
              refresh,
              setRefresh,
              searchText: searchText,
              onSearchChange: (e) => requestSearch(e.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </Box>
    </Box>
  );
}
