import {
    Grid,
    Paper,
    Typography,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paperLogo: {
        marginTop: theme.spacing(2),
        backgroundImage: `url("/facilities.jpg")`,
        backgroundPosition: 'center',
        width: "100%",
        height: "13.25em",
    },
    paperHero: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    typoHero: {
        letterSpacing: ".125em",
        fontFamily: "'Barlow Condensed', sans-serif",
        fontWeight: 600,
        fontSize: "2.5em",
        textTransform: 'uppercase',
        lineHeight: "1.5em",
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: theme.spacing(2),
    },
}))

export default function HomeHeader(props) {
    const classes = useStyles();
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
                <Grid item xs={12}>
                    <Paper className={classes.paperHero} square style={{background: "#bf5700", color: "#fff"}}>
                        <Typography className={classes.typoHero}>
                        Transforming
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paperHero} square style={{background: "#bf5700", color: "#fff"}}>
                        <Typography className={classes.typoHero}>
                        Ideas into
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paperHero} square style={{background: "#f8971f", color: "#333f48"}}>
                        <Typography className={classes.typoHero}>
                        Realities
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
                <Paper className={classes.paperLogo}>

                </Paper>
            </Grid>
        </Grid>
    )
}