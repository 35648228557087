/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: queries.js
 * Description: This file contains custom made API Fetch queries
 * for AWS Amplify. Based upon the queries generated by running
 * amplify codegen.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Member Queries
export const getMemberProfile = /* GraphQL */ `
  query GetMemberProfile($eid: ID!) {
    getMember(eid: $eid) {
      eid
      firstName
      lastName
      profilePicture {
        bucket
        key
        region
        url
        uploadUrl
      }
      isAdminMember
      isStaffMember
      phone
      checkInStatus
      email
      conductStatus
      trainingList {
        name
        level
        updatedAt
      }
      owner
    }
  }
`;

export const getMemberTrainings = /* GraphQL */ `
  query GetMemberTrainings($eid: ID!) {
    getMember(eid: $eid) {
      trainingList {
        name
        level
        updatedAt
      }
    }
  }
`;

export const getMemberTransactions = /* GraphQL */ `
  query GetMemberTransactions($eid: ID!, $nextToken: String, $limit: Int) {
    getMember(eid: $eid) {
      transactionRecord(
        nextToken: $nextToken
        limit: $limit
        sortDirection: DESC
      ) {
        nextToken
        items {
          createdAt
          transactionType
          toolId
          printId
          staffMemberId
        }
      }
    }
  }
`;

export const listCheckedInMembers = /* GraphQL */ `
  query ListCheckedInMembers(
    $checkInStatus: Int!
    $workstationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembersByStatus(
      checkInStatus: $checkInStatus
      workstationId: $workstationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        eid
        firstName
        lastName
        checkInStatus
      }
      nextToken
    }
  }
`;

export const getAllMembers = /* GraphQL */ `
  query GetAllMembers(
    $eid: ID
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMembers(
      eid: $eid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        eid
        firstName
        lastName
        updatedAt
        isStaffMember
        isAdminMember
        isFacultyMember
        email
        phone
        checkInStatus
        conductStatus
        owner
        updatedAt
      }
      nextToken
    }
  }
`;

// Tool Queries
export const getToolStatus = /* GraphQL */ `
  query GetToolStatus($id: ID!) {
    getMachine(id: $id) {
      status
    }
  }
`;

export const getToolIdByName = /* GraphQL */ `
  query ListMachinesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMachineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMachinesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getToolHolder = /* GraphQL */ `
  query GetToolHolder($id: ID!) {
    getMachine(id: $id) {
      id
      currentHolderId
      status
    }
  }
`;

export const getToolHistory = /* GraphQL */ `
  query GetToolHistory($id: ID!, $limit: Int) {
    getMachine(id: $id) {
      id
      currentHolderId
      name
      transactionRecord(limit: $limit, sortDirection: DESC) {
        items {
          id
          createdAt
          transactionType
          member {
            eid
            firstName
            lastName
          }
          staffMember {
            eid
            firstName
            lastName
          }
          transactionComment
        }
        nextToken
      }
    }
  }
`;

export const listCheckedOutTools = /* GraphQL */ `
  query ListCheckedOutTools(
    $filter: ModelToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        description
        location
      }
    }
  }
`;

export const getAllMachines = /* GraphQL */ `
  query GetAllTools(
    $filter: ModelMachineFilterInput
    $nextToken: String
    $limit: Int
  ) {
    listMachines(nextToken: $nextToken, limit: $limit, filter: $filter) {
      items {
        id
        name
        currentHolderId
        machineGroup {
          name
          area {
            name
          }
        }
        status
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

// Print Queries
export const getAllPrints = /* GraphQL */ `
  query GetAllPrints(
    $filter: ModelPrintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberId
        name
        printStatus
        createdAt
      }
      nextToken
    }
  }
`;

export const getPrint = /* GraphQL */ `
  query GetPrint($id: ID!) {
    getPrint(id: $id) {
      id
      name
      stlFile
      description
      material
      printerName
      printerType
      cost
      member {
        eid
        firstName
        lastName
      }
      printStatus
      createdAt
      updatedAt
    }
  }
`;

// fetch Content
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      title
      subtitle
      displaySubtitle
      mainText
      previewText
      images
      previewImage
      updatedAt
    }
  }
`;

export const getAllContent = /* GraphQL */ `
  query GetAllContent(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        displaySubtitle
        mainText
        previewText
        images
        previewImage
        updatedAt
      }
      nextToken
    }
  }
`;

// Reservations
export const listReservableAreas = /* GraphQL */ `
  query ListReservableAreas(
    $reserve: Int
    $sortDirection: ModelSortDirection
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservableAreas(
      reserve: $reserve
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        hours {
          day
          openingTime
          closingTime
        }
        slotDuration
      }
      nextToken
    }
  }
`;

// Area
export const getArea = /* GraphQL */ `
  query GetArea($id: ID!) {
    getArea(id: $id) {
      id
      name
      roomNumber
      hours {
        day
        open
        openingTime
        closingTime
      }
      description
      createdAt
      updatedAt
    }
  }
`;

export const listAreas = /* GraphQL */ `
  query ListAreas($filter: ModelAreaFilterInput, $limit: Int) {
    listAreas(filter: $filter, limit: $limit) {
      items {
        id
        name
        roomNumber
      }
    }
  }
`;

export const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        units
        price
        count
        created
        updated
        createdAt
        updatedAt
        machineGroupMaterialsId
        materialConsumerId
      }
      nextToken
    }
  }
`;

// Trainings
export const listTrainings = /* GraphQL */ `
  query ListTrainings(
    $filter: ModelTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        canvasId
        canvasName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTraining = /* GraphQL */ `
  query GetTraining($id: ID!) {
    getTraining(id: $id) {
      name
      canvasId
      canvasName
      published
      machineGroupId
      machineGroup {
        name
      }
      description
    }
  }
`;

export const listMachineGroupNames = /* GraphQL */ `
  query ListMachineGroups(
    $filter: ModelMachineGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMachineGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        area {
          id
          name
        }
        machines {
          scannedCount
        }
      }
      nextToken
    }
  }
`;

export const getMachineGroup = /* GraphQL */ `
  query GetMachineGroup($id: ID!) {
    getMachineGroup(id: $id) {
      id
      name
      description
      slotDuration
      type
      requiredTrainings
      reservable
      areaId
      area {
        name
      }
      machines {
        items {
          id
          name
        }
      }
    }
  }
`;

export const listMachinesByGroup = /* GraphQL */ `
  query ListMachinesByGroup(
    $machineGroupId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMachineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMachinesByGroup(
      machineGroupId: $machineGroupId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        machineGroupId
        created
        updated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFacilities = /* GraphQL */ `
  query ListFacilities($filter: ModelAreaFilterInput) {
    listAreas(filter: $filter) {
      items {
        id
        name
        roomNumber
      }
    }
    listMachineGroups {
      items {
        area {
          id
          name
        }
        machines {
          scannedCount
        }
        id
        name
        updatedAt
      }
    }
    listMachines {
      items {
        id
        name
      }
    }
    listMaterials {
      items {
        id
        name
        machineGroupMaterialsId
        count
      }
    }
  }
`;

export const listProjectsByEid = /* GraphQL */ `
  query ListProjectsByEid(
    $ownerId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByEid(
      ownerId: $ownerId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownerId
        description
        teamId
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getClass = /* GraphQL */ `
  query GetClass($classId: String!) {
    getClass(classId: $classId) {
      classId
      count
      createdAt
      updatedAt
    }
  }
`;

export const listResearchByEid = /* GraphQL */ `
  query ListResearchByEid(
    $ownerId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResearchByEid(
      ownerId: $ownerId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        name
        supervisor
        description
        status
        accountNo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
