import { Box, Button, makeStyles, Paper } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { listMachineGroupNames } from "api/queries";
import { API } from "aws-amplify";
import { Header, PaperAccent } from "components";
import { formatTime } from "helpers";
import AppContext from "helpers/AppContext";
import { useContext, useEffect, useState } from "react";
import { MachineGroupDialog } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "35em",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  newMachineGroup: {
    height: "3em",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    height: "30em",
    width: "100%",
    overflow: "auto",
  },
  tableCell: {
    borderBottomColor: theme.palette.divider,
  },
  tableHeader: {
    backgroundColor: theme.palette.background.paper,
  },
}));

async function getMachineGroups() {
  let request = {
    query: listMachineGroupNames,
  };

  let response = await API.graphql(request);
  return response.data.listMachineGroups.items;
}

export default function MachineGroup(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [createMachineGroup, setCreateMachineGroup] = useState(false);
  const { setErrState } = useContext(AppContext);
  const [dialogTask, setDialogTask] = useState("create");
  const [groupId, setGroupId] = useState("");
  const [loading, setLoading] = useState(true);
  const { facilities, setFacilities } = props;

  useEffect(() => {
    setLoading(facilities.loading);
    setRows(facilities.machineGroups);
  }, [facilities]);

  // useEffect(() => {
  //   console.log(rows)
  // }, [rows])

  const handleManageMachineGroup = (id) => {
    setDialogTask("manage");
    setGroupId(id);
    setCreateMachineGroup(true);
  };

  const handleNewGroup = () => {
    setDialogTask("create");
    setCreateMachineGroup(true);
  };

  const handleDialogClose = () => {
    setCreateMachineGroup(false);
    setLoading(true);
    getMachineGroups()
      .then((result) => {
        setFacilities((prevValues) => {
          return {
            ...prevValues,
            machineGroups: result,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        setErrState({
          severity: "error",
          open: true,
          text: "Unable to refresh Machine Groups.",
        });
      });
  };

  const columns = [
    { field: "name", headerName: "Name", width: 165, flex: 1 },
    {
      field: "manage",
      headerName: " ",
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.manageButton}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(id) => handleManageMachineGroup(params.id)}
            >
              Manage
            </Button>
          </div>
        );
      },
    },
    {
      field: "area_name",
      headerName: "Area",
      width: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.area.name}`;
      },
    },
    {
      field: "machines.scannedCount",
      headerName: "Count",
      width: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.machines.scannedCount}`;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Update",
      flex: 1,
      valueFormatter: (params) => {
        const formatted = formatTime(params);
        return `${formatted}`;
      },
    },
  ];

  return (
    <Paper className={classes.root}>
      <MachineGroupDialog
        open={createMachineGroup}
        onClose={handleDialogClose}
        task={dialogTask}
        machineGroupId={groupId}
        facilities={facilities}
      />
      <PaperAccent loading={loading} />
      <Box className={classes.title}>
        <Header text="Tool Groups" variant="h4" />
        <Button
          variant="contained"
          color="primary"
          className={classes.newMachineGroup}
          onClick={handleNewGroup}
        >
          New Tool Group
        </Button>
      </Box>
      <Box p={2} className={classes.table}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={50}
          loading={loading}
        />
      </Box>
    </Paper>
  );
}
