import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    Grid,
    DialogActions,
    makeStyles,
    TextField,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@material-ui/core';
import { API, Storage } from 'aws-amplify';
import { useEffect, useState, useContext  } from 'react';
import { Header } from '..';
import AppContext from '../../helpers/AppContext';
import { CloudUpload } from '@material-ui/icons';
import { getMemberProfile } from '../../api/queries';
import { createPrint } from '../../api/mutations';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }, 
    formControl: {
        margin: theme.spacing(2),
    },
    printName: {
        width: '45%',
        minWidth: 180,
    },
    printStatus: {
        width: '20%',
        minWidth: 100,
    },
    printer: {
        width: '25%',
        minWidth: 120,
    },
    printDescription: {
        minWidth: '95%',
    },
    eidField: {
        width: '25%',
    },
    deviceName: {
        width: "60%",
    },
    printMaterial: {
        width: "30%",
    }

}))

const PRINTERS = [
    'FormLabs',
    'Dremel',
    'CraftBot XL',
    'CraftBot Plus',
    'MarkForge'
]

const PRINTSTATUS = [
    "RECEIVED",
	"VERIFIED", // Temps are exact requirements
	"STARTED",
	"ERROR",
	"PROCESSING",// Specific to SLAs
	"COMPLETE",
	"CANCELLED",
	"PICKED_UP",
]

async function getUser(eid) {
    if (eid.length >= 5) {
        const request = {
            query: getMemberProfile,
            variables: {
                eid: eid
            }
        }
        let response = await API.graphql(request);
        return response.data.getMember;
    }
    return 0;
}

async function submitPrint(inputVars) {
    const request = {
        query: createPrint,
        variables: {
            input: inputVars
        }
    }

    let response = await API.graphql(request);
    return response.data.createPrint;
}

function formatPrintStatus(status) {
    let words = status.split('_');
    for (let i in words) {
        words[i] = words[i].toLowerCase();
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    status = words.join(' ');
    return status;
}

export default function NewPrintDialog(props) {
    const initPrint = {
        name: "",
        description: "",
        printStatus: "STARTED",
        memberEid: "",
        member: {
            eid: "none",
        },
        material: "",
        printerName: "",
    }

    const { loggedInMember, userPerms, setErrState } = useContext(AppContext);
    const { open, onClose } = props;
    const [ print, setPrint ] = useState(initPrint);
    const [ valid, setValid ] = useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setPrint(initPrint);
        onClose();
    }

    const handleUpload = () => {

    }

    const handleSubmit = () => {
        const inputVars = {
            name: print.name,
            memberId: print.memberEid.toLowerCase().trim(),
            description: print.description,
            printStatus: print.status,
            printerName: print.printerName
        }
        let userFound = false;

        getUser(print.memberEid).then((result) => {
            if (result.checkInStatus !== 1) {
                throw new Error("Member must be checked in to submit an overnight print!");
            }
            submitPrint(inputVars).then((result) => {
                console.log(result);
                setErrState({
                    severity: 'success',
                    open: true,
                    text: "Successfully added Print"
                })
                handleClose();
            }).catch((err) => {
                setErrState({
                    severity: 'error',
                    open: true,
                    text: "That's gonna be a nah from me, dawg. Can't create Print"
                })
                console.log(err);
            })
        }).catch((err) => {
            setErrState({
                severity: 'error',
                open: true,
                text: err.message,
            })
        });
        console.log(userFound);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        if (name === "printStatus") {
            value = PRINTSTATUS[event.target.value].toUpperCase();
            value = value.split(' ');
            value = value.join('_');
        } else if (name === 'printerType') {
            value = PRINTERS[event.target.value];
        } else if (name === 'memberEid' || name === 'name' || name === 'printerName') {
            value = event.target.value;
            if (value === "") {
                setValid(false);
            }
        } 
        setPrint({
            ...print,
            [name]: value
        });
    }

    // Button tings
    useEffect(() => {
        if (print.name !== "" && print.memberEid !== "" && print.printerName !== "") {
            setValid(true);
        } else if (print.name === "" || print.memberEid === "" || print.printerName === "") {
            setValid(false);
        }
    }, [print.memberEid, print.name, print.printerName])

    // useEffect(() => {
    //     setInterval(() => {
    //         getUser(print.memberEid).then((result) => {
    //             if (result != 0) {
    //                 setPrint({
    //                     ...print,
    //                     firstName: result.firstName,
    //                     lastName: result.lastName
    //                 })
    //             }
    //         }).catch((err) => {
    //             setPrint({
    //                 ...print,
    //                 firstName: "",
    //                 lastName: ""
    //             });
    //         })
    //     });
    // }, [print.memberEid])

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            scroll="paper"
            open={open}
            aria-labelledby="print-dialog"
            onClose={handleClose}
        >
            <DialogTitle>
                <Header variant="h4" p={0} text="New Print" />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Paper>
                            <Box p={2} pt={0}>
                                <form className={classes.root}>
                                    <FormControl className={classes.printName}>
                                        <TextField
                                            id="print-name"
                                            label="Print Name"
                                            value={print.name}
                                            variant="outlined"
                                            inputProps={{
                                                name: 'name'
                                            }}
                                            required
                                            onInput={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.printStatus}>
                                        <InputLabel id="print-status">Status</InputLabel>
                                        <Select
                                            labelId="print-status"
                                            id="print-status"
                                            disabled={!userPerms.staff}
                                            variant="outlined"
                                            label="Status"
                                            defaultValue={PRINTSTATUS.indexOf(print.printStatus)}
                                            inputProps={{
                                                name: 'printStatus',
                                            }}
                                            onChange={handleChange}
                                        >
                                            {PRINTSTATUS.map((status, idx) => {
                                                return (
                                                    <MenuItem value={idx} key={`print-status-selection-${idx}`}>{formatPrintStatus(status)}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.eidField}>
                                        <TextField
                                            id="member-eid"
                                            label="Member Eid"
                                            value={print.memberEid}
                                            variant="outlined"
                                            inputProps={{
                                                name: "memberEid"
                                            }}
                                            required
                                            onInput={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.deviceName}>
                                        <TextField
                                            id="device-name"
                                            label="Printer Name"
                                            value={print.printerName}
                                            variant="outlined"
                                            inputProps={{
                                                name: "printerName"
                                            }}
                                            required
                                            onInput={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.printMaterial}>
                                        <TextField
                                            id="print-material"
                                            label="Material"
                                            value={print.material}
                                            variant="outlined"
                                            inputProps={{
                                                name: "material"
                                            }}
                                            onInput={handleChange}
                                            disabled
                                        />
                                    </FormControl>
                                    <TextField
                                        id="print-description"
                                        label="Description"
                                        inputProps={{
                                            name: 'description',
                                            maxLength: 250
                                        }}
                                        defaultValue={print.description}
                                        className={classes.printDescription}
                                        multiline
                                        rows={4}
                                        onInput={handleChange}
                                    />
                                    
                                </form>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Paper>
                            <Header variant="h5" text="Details" />
                            <Box p={2} pt={0}>
                                Member Id: {print.memberEid} <br />
                                Member Name: {print.firstName} {print.lastName} <br />
                                <Button
                                    varaint="contained"
                                    color="secondary"
                                    startIcon={<CloudUpload />}
                                    onClick={handleUpload}
                                    disabled={true}
                                >
                                    Upload STL
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box>
                    <ButtonGroup color="primary" variant="contained">
                        <Button onClick={handleSubmit} disabled={!valid}>
                            Submit Print
                        </Button>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogActions>
        </Dialog>
    )
}