/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StudentNav.js
 * Description: This file contains the code for the Student
 * navigation options in the Menu Drawer.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { 
    ListItem,
    ListItemIcon,
    ListItemText, 
} from '@material-ui/core';
import {
    Link,
} from 'react-router-dom';

//icons
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PrintIcon from '@material-ui/icons/Print';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
// import FeedbackIcon from '@material-ui/icons/Feedback';

const studentRoutes = [
    {
        title: "Prints",
        key: "nav-student-prints",
        icon: <PrintIcon fontSize='large' />,
        path: "/students/prints",
    },
    {
        title: "Trainings",
        key: "nav-student-trainings",
        icon: <FitnessCenterIcon fontSize='large' />,
        path: "/students/trainings",
    },
    // {
    //     title: "Reservations",
    //     key: "nav-student-reservations",
    //     icon: <EventAvailableIcon fontSize='large' />,
    //     path: "/students/reservations",
    // },
    // {
    //     title: "Feedback",
    //     key: "nav-student-feedback",
    //     icon: <FeedbackIcon fontSize='large' />,
    //     path: "/students/feedback",
    // }
]

export default function StudentNav(props) {
    const { classes, drawerOpen, toggleDrawer, current} = props;

    return (
        <div id="student-nav">
            {studentRoutes.map((route) => {
                return (
                    <div key={route.key}>
                        <Link to={route.path} className={classes.navLink}>
                            <ListItem 
                                button
                                key={route.key} 
                                onClick={drawerOpen ? toggleDrawer : null}
                                selected={current === route.path}
                            >
                                <ListItemIcon className={classes.icon}>
                                    {route.icon}
                                </ListItemIcon>
                                <ListItemText 
                                    primary={route.title} 
                                />
                            </ListItem>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}