import { 
    Box, 
    Button, 
    Card, 
    Grid, 
    Paper, 
    Typography 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Header, PaperAccent } from "components";
import canvasQueries from "helpers/canvasQueries";
import { useState, useEffect, useContext } from "react";
import { listTrainings } from 'api/queries';
import { API } from 'aws-amplify';
import ManageTrainingsDialog from "./manageTrainingsDialog";
import AppContext from "helpers/AppContext";

const useStyles = makeStyles((theme) => ({
    training: {
        height: "30em",
        maxHeight: "30em",
        // overflow: 'scroll'
    },
    trainingCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '12em',
        width: "100%",
        paddingTop: 20,
    },
    addAreaCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '12em',
        width: "100%",
    },
    trainingBox : {
        display: 'flex',
        overflow: 'auto',
        height: '25em',
        justifyContent: 'center',
    },
    trainingGrid: {
        width: "95%",
        paddingLeft: "2.5%",
        paddingRight: '2.5%',
        // overflow: 'scroll'
    },
    detailsButton: {
        marginBottom: 'auto',
    },
    trainingSummary: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    addArea: {
        height: '100%',
        width: '100%',
    },
    avatar: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
    },
    syncButton: {
        height: "3em",
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}))

async function getTrainings() {
    let request = {
        query: listTrainings
    }

    let response = await API.graphql(request)
    return response.data.listTrainings.items
}

export default function Trainings(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([])
    const [trainingId, setTrainingId] = useState("")
    const [manageTraining, setManageTraining] = useState(false)
    const {setErrState} = useContext(AppContext);

    useEffect(() => {
        getTrainings().then((result) => {
            setTrainings(result)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const handleManageTraining = (id) => {
        setTrainingId(id)
        setManageTraining(true);
    }

    const onClose = () => {
        setManageTraining(false)
    }

    const clickSyncHandler = async () => {
        setLoading(true)
        canvasQueries.syncTrainings().then((result) => {
            let message = "";
            if (result) {
                message = "Added new trainings to TIW Portal list."
            } else {
                message = "No new trainings found on Canvas"
            }
            getTrainings().then((result) => {
                setTrainings(result);
            });
            setErrState({
                severity: 'success',
                open: true,
                text: message
            })
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            setErrState({
                severity: "error",
                open: true,
                text: "Unable to sync trainings from Canvas. If this error persists, contact TIW Software Team"
            })
            console.log(err);
        })
        // let sync = await canvasQueries.syncTrainings()
        // if (sync) {
        //     let newTrainingList = await getTrainings()
        //     setTrainings(newTrainingList);

        // }
        // setLoading(false)
    }

    return (
        <Paper className={classes.training}>
            <ManageTrainingsDialog open={manageTraining} onClose={onClose} id={trainingId}/>
            <PaperAccent loading={loading} />
            <Box className={classes.title}>
                <Header variant="h4" text="Training" />
                <Button color="primary" variant="contained" className={classes.syncButton} onClick={() => clickSyncHandler()}>
                    Sync Trainings
                </Button>
            </Box>
            <Box className={classes.trainingBox}>
                <Grid container spacing={2} className={classes.trainingGrid}>
                    { loading ? null :
                        trainings.map((training) => {
                            return (
                                <Grid item xs={12} sm={6} md={3} key={`area-${training.id}`}>
                                    <div align="center">
                                        <Card variant="outlined" className={classes.trainingCard}>
                                            <Box className={classes.trainingSummary} pb={2}>
                                                <Typography noWrap variant="h4">
                                                    {training.name}
                                                </Typography>
                                                <Typography noWrap variant="h5">
                                                    {training.published ? "Published" : "Unpublished"}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.detailsButton} pb={2}>
                                                <Button color="primary" variant="contained" size="small" onClick={() => handleManageTraining(training.id)}>
                                                    Manage Training
                                                </Button>
                                            </Box>
                                        </Card>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
        </Paper>
    )
}