import Amplify, { API, Auth } from "aws-amplify";
import { getAllContent } from "../api/queries";
import routeMaker from "./routeMaker";

export default async function prefetchContent() {
    let request = {
        query: getAllContent,
        variables: {
            limit: 50,
            nextToken: null,
        },
        // authMode: 'AWS_IAM',
    }
    let credentials = await Auth.currentCredentials()
    Amplify.configure({
      aws_appsync_authenticationType: credentials.authenticated ?
      "AMAZON_COGNITO_USER_POOLS" :
      "AWS_IAM"
    })

    let response = await API.graphql(request);
    let content = response.data.listContents.items;
    content = Object.assign({}, ...content.map(s => ({[s.id]: s})));
    let slugs = Object.assign({}, ...response.data.listContents.items.map(s => ({
        [routeMaker(s.title)]: s.id
    })))
    content["slugs"] = slugs;
    return content;
}