/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: Preview.js
 * Description: This little preview box will link to the ap-
 * propriate page containing the content of a larger box.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
  makeStyles,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "helpers/AppContext";
import routeMaker from "helpers/routeMaker";
import { Header } from "components";
import fetchContent from "helpers/fetchContent";

let imageBucket;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  imageBucket = "https://portal-prints165204-dev.s3.amazonaws.com/public/";
} else {
  imageBucket =
    "https://portal-prints113547-production.s3.amazonaws.com/public/";
}

const useStyles = makeStyles((theme) => ({
  root: {},
  paperPromo: {
    height: "25em",
    maxHeight: "25em",
    width: "100%",
  },
  previewCard: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    height: "30em",
  },
  loadingCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "30em",
  },
  previewText: {
    height: "5em",
  },
  cardMedia: {
    height: "15em",
  },
  learnMore: {
    display: "flex",
    justifyContent: "right",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    height: "5em",
  },
  navLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 18,
  },
}));

export default function Preview(props) {
  const initContent = {
    title: "",
    previewText: "",
    subtitle: "",
  };
  const { id } = props;
  const { content, setContent } = useContext(AppContext);
  const [thisContent, setThisContent] = useState(initContent);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (content[id] != undefined) {
      setThisContent(content[id]);
      setLoading(false);
    } else {
      fetchContent(id)
        .then((result) => {
          setThisContent(result);
          setContent({
            ...content,
            id: result,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const route = routeMaker(thisContent.title);

  return (
    <div>
      {loading ? (
        <Card className={classes.loadingCard}>
          <CircularProgress size={64} />
        </Card>
      ) : (
        <Card className={classes.previewCard}>
          <CardMedia
            className={classes.cardMedia}
            image={`${imageBucket}${thisContent.previewImage}`}
          />
          <CardContent>
            <Header variant="h5" text={thisContent.title} />
            <Box p={2} pt={0} className={classes.previewText}>
              {thisContent.subtitle}
            </Box>
          </CardContent>
          <CardActions className={classes.learnMore}>
            <Link to={"learn/" + route} className={classes.navLink}>
              <Button color="primary" size="large">
                Learn More >
              </Button>
            </Link>
          </CardActions>
        </Card>
      )}
    </div>
  );
}
