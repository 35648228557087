/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: MessageBar.js
 * Description: Code for creating an error bar to notify the
 * user of why something did not work as intended. Should be
 * used in _every_ catch block throughout the code.
 * 
 * Must be provided with an errState object with the props: 
 * {
 *      severity: <severity level>,
 *      open: false,
 *      text: <text description of the problem>
 * }
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { 
    Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function MessageBar(props) {
    const { state, setState } = props;

    const handleClose = (event, reason) => {
        setState({
            severity: state.severity,
            open: false,
            text: state.text,
        });
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={state.open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
        <Alert elevation={6} variant="filled" severity={state.severity} onClose={handleClose}>
            {state.text}
        </Alert>
        </Snackbar>
    )
};

// export default ErrorBar;