/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: mutations.js
 * Description: Contains customized mutation (Create, Update,
 * Delete) graphql calls for AWS Ampliy. These are based upon
 * the mutations file generated by running amplify codegen.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Members API cals
export const updateMemberTrainings = /* GraphQL */ `
  mutation UpdateMemberTrainings(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      eid
      trainingList {
        name
        level
        updatedAt
      }
    }
  }
`;

export const updateMemberProfile = /* GraphQL */ `
  mutation UpdateMemberProfile(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      eid
      firstName
      lastName
      profilePicture {
        bucket
        key
        region
        url
        uploadUrl
      }
      isAdminMember
      isStaffMember
      phone
      checkInStatus
      email
      conductStatus
      trainingList {
        name
        level
        updatedAt
      }
      owner
    }
  }
`;

export const createMemberProfile = /* GraphQL */ `
  mutation CreateMemberProfile(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      eid
      firstName
      lastName
      profilePicture {
        bucket
        key
        region
        url
        uploadUrl
      }
      isAdminMember
      isStaffMember
      phone
      checkInStatus
      email
      conductStatus
      trainingList {
        name
        level
        updatedAt
      }
      owner
    }
  }
`;

export const updateMemberCheckInStatus = /* GraphQL */ `
  mutation CheckInMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      eid
      firstName
      lastName
      profilePicture {
        bucket
        key
        region
        url
        uploadUrl
      }
      phone
      checkInStatus
      email
      conductStatus
      trainingList {
        name
        level
        updatedAt
      }
      owner
    }
  }
`;

// Tools
export const createToolTransaction = /* GraphQL */ `
    mutation CreateToolTransaction(
        
    )
`;

export const createTransaction = /* GraphQL */ `
  mutation createTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      transactionStatus
    }
  }
`;

export const updateToolHolderId = /* GraphQL */ `
  mutation MemberCheckOutTool(
    $input: UpdateMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    updateMachine(input: $input, condition: $condition) {
      id
      currentHolderId
      status
    }
  }
`;

export const createNewTool = /* GraphQL */ `
  mutation CreateNewTool(
    $input: CreateToolInput!
    $condition: ModelToolConditionInput
  ) {
    createTool(input: $input, condition: $condition) {
      id
      name
      description
    }
  }
`;

// Prints
export const updatePrint = /* GraphQL */ `
  mutation UpdatePrint(
    $input: UpdatePrintInput!
    $condition: ModelPrintConditionInput
  ) {
    updatePrint(input: $input, condition: $condition) {
      id
      name
      stlFile
      description
      material
      printerType
      cost
      member {
        eid
        firstName
        lastName
      }
      printStatus
      createdAt
      updatedAt
    }
  }
`;

export const createPrint = /* GraphQL */ `
  mutation CreatePrint(
    $input: CreatePrintInput!
    $condition: ModelPrintConditionInput
  ) {
    createPrint(input: $input, condition: $condition) {
      id
    }
  }
`;

// Content
export const modifyContent = /* GraphQL */ `
  mutation ModifyContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      title
      subtitle
      displaySubtitle
      mainText
      previewText
      images
      previewImage
      updatedAt
    }
  }
`;

export const addImageToContent = /* GraphQL */ `
  mutation AddImageToContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      images
    }
  }
`;

export const setPreviewImage = /* GraphQL */ `
  mutation SetPreviewImage(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      previewImage
    }
  }
`;

export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
    }
  }
`;

// Set up Area
export const createArea = /* GraphQL */ `
  mutation CreateArea($input: CreateAreaInput!) {
    createArea(input: $input) {
      id
    }
  }
`;

export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      name
      hours {
        day
        open
        openingTime
        closingTime
      }
      description
      createdAt
      updatedAt
      tools {
        nextToken
      }
    }
  }
`;

// Set up Machine Group
export const createMachineGroup = /* GraphQL */ `
  mutation CreateMachineGroup(
    $input: CreateMachineGroupInput!
    $condition: ModelMachineGroupConditionInput
  ) {
    createMachineGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateMachineGroup = /* GraphQL */ `
  mutation UpdateMachineGroup(
    $input: UpdateMachineGroupInput!
    $condition: ModelMachineGroupConditionInput
  ) {
    updateMachineGroup(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

// Set up Machine
export const createMachine = /* GraphQL */ `
  mutation CreateMachine(
    $input: CreateMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    createMachine(input: $input, condition: $condition) {
      id
      name
      status
      updatedAt
    }
  }
`;

export const updateMachine = /* GraphQL */ `
  mutation UpdateMachine(
    $input: UpdateMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    updateMachine(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial(
    $input: CreateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    createMaterial(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
    }
  }
`;

// Trainings
export const createTraining = /* GraphQL */ `
  mutation CreateTraining(
    $input: CreateTrainingInput!
    $condition: ModelTrainingConditionInput
  ) {
    createTraining(input: $input, condition: $condition) {
      id
      name
      canvasId
      canvasName
      published
    }
  }
`;

export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      name
      units
      price
      count
    }
  }
`;

// Classes, Projects, Research
export const createClass = /* GraphQL */ `
  mutation CreateClass(
    $input: CreateClassInput!
    $condition: ModelClassConditionInput
  ) {
    createClass(input: $input, condition: $condition) {
      classId
    }
  }
`;

// TODO: create lambda for atomic operation. Race condition if two people are
//       checking in at the same time.
export const updateClass = /* GraphQL */ `
  mutation UpdateClass(
    $input: UpdateClassInput!
    $condition: ModelClassConditionInput
  ) {
    updateClass(input: $input, condition: $condition) {
      classId
      count
    }
  }
`;

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, conditino: $condition) {
      id
      count
    }
  }
`;

export const createResearch = /* GraphQL */ `
  mutation CreateResearch(
    $input: CreateResearchInput!
    $condition: ModelResearchConditionInput
  ) {
    createResearch(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateResearch = /* GraphQL */ `
  mutation UpdateResearch(
    $input: UpdateResearchInput
    $condition: ModelResearchConditionInput
  ) {
    updateResearch(intput: $input, condition: $condition) {
      id
      name
      supervisor
      accountNo
    }
  }
`;

export const deleteMachine = /* GraphQL */` 
  mutation deleteMachine(
    $input: DeleteMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    deleteMachine(input: $input, condition: $condition) {
      id
      name
    }
  }
`;