import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    DialogActions,
    makeStyles,
    TextField,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import IMask from 'imask'
import { API } from 'aws-amplify';
import { useState, useContext } from 'react';
import { Header } from '../../../components';
import AppContext from '../../../helpers/AppContext';
import formatStatus from '../../../helpers/formatStatus'
import { createResearch } from '../../../api/mutations';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }, 
    formControl: {
        margin: theme.spacing(2),
    },
    researchName: {
        minWidth: '25%',
    },
    researchStatus: {
        minWidth: '20%',
    },
    researchSuper: {
        minWidth: '25%'
    },
    researchAccount: {
        minWidth: '20%'
    },
    researchDescription: {
        minWidth: '95%',
    },
}))

const RESEARCHSTATUS = [
    "IN_PROGRESS",
    "COMPLETED"
]

async function submitResearch(inputVars) {
    const request = {
        query: createResearch,
        variables: {
            input: inputVars
        }
    }

    let response = await API.graphql(request)
    return response.data.createResearch
}

export default function NewResearchDialog(props) {
    const initResearch = {
        name: "",
        supervisor: "",
        accountNum: "",
        status: "IN_PROGRESS",
        description: "",
        researchStatus: 0
    }

    const { loggedInMember, userPerms, setErrState } = useContext(AppContext)
    const { open, onClose, checkIn } = props
    const [ research, setResearch ] = useState(initResearch)
    const [ valid, setValid ] = useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setResearch(initResearch)
        onClose();
    }

    // TODO: validation!
    const handleSubmit = () => {
        const inputVars = {
            name: research.name,
            ownerId: loggedInMember.eid,
            description: research.description,
            status: "IN_PROGRESS",
            supervisor: research.supervisor,
            accountNo: research.accountNum
        }
        submitResearch(inputVars).then((result) => {
            console.log(result)
            setErrState({
                severity: 'success',
                open: true,
                text: 'Successfully Added Research'
            })
            checkIn('research', result.id);
            handleClose();
        }).catch((err) => {
            setErrState({
                severity: 'error',
                open: true,
                text: "That's gonna be a nah from me, dawg. Can't create Research"
            })
            console.log(err)
        })
    }

    const accountMask = IMask.createMask({
        mask: '#!-0000-0000',
        definitions: {
            '#': /[1-3]/,
            '!': /[0, 4, 6, 8, 9]/
        }
    })

    const handleChange = (event) => {
        const name = event.target.name
        let value = event.target.value;
        if (name === 'accountNum') {
            value = accountMask.resolve(value)
        }
        let v = (research.name !== "") && (research.supervisor !== "") && (research.accountNum !== "")
        setValid(v)
        setResearch({
            ...research,
            [name]: value
        })
    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            scroll="paper"
            open={open}
            aria-labelledby="research-dialog"
            onClose={handleClose}
        >
            <DialogTitle>
                <Header variant="h4" p={0} text="New Research" />
            </DialogTitle>
            <DialogContent>
                <Paper>
                    <Box p={2} pt={0}>
                        <form className={classes.root}>
                            <FormControl className={classes.researchName}>
                                <TextField
                                    id="research-name"
                                    label="Research Project Name"
                                    value={research.name}
                                    variant="outlined"
                                    inputProps={{
                                        name: 'name'
                                    }}
                                    required
                                    onInput={handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.researchStatus}>
                                <InputLabel id="research-status">Status</InputLabel>
                                <Select
                                    labelId="research-status"
                                    id="research-status"
                                    variant="outlined"
                                    label="Status"
                                    defaultValue={RESEARCHSTATUS.indexOf(research.status)}
                                    inputProps={{
                                        name: 'researchStatus',
                                    }}
                                    onChange={handleChange}
                                >
                                    {RESEARCHSTATUS.map((status, idx) => {
                                        return (
                                            <MenuItem value={idx} key={`print-status-selection-${idx}`}>{formatStatus(status)}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.researchSuper}>
                                <TextField
                                    required
                                    id="research-supervisor"
                                    variant="outlined"
                                    label="Supervising Professor"
                                    inputProps={{
                                        name: "supervisor"
                                    }}
                                    defaultValue={research.supervisor}
                                    onInput={handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.researchAccount}>
                                <TextField
                                    required
                                    helperText={"Valid Account Numbers start with 14, 18, 19, 20, 26, 30, 36. You will be held responsible for any charges until a valid account number is attached to a research project."}
                                    id="research-account"
                                    variant="outlined"
                                    label="AccountNo"
                                    inputProps={{
                                        name: "accountNum"
                                    }}
                                    defaultValue={research.accountNum}
                                    value={research.accountNum}
                                    onInput={handleChange}
                                />
                            </FormControl>
                            <TextField
                                id="research-description"
                                label="Description"
                                variant="outlined"
                                inputProps={{
                                    name: "description",
                                    maxLength: 250
                                }}
                                defaultValue={research.description}
                                className={classes.researchDescription}
                                multiline
                                rows={4}
                                onInput={handleChange}
                            />
                        </form>
                    </Box>
                </Paper>
                <DialogActions>
                    <Box>
                        <ButtonGroup color="primary" variant="contained">
                            <Button onClick={handleSubmit} disabled={!valid}>
                                Create Research Project
                            </Button>
                            <Button onClick={handleClose}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
