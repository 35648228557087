/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: MenuDrawer.js
 * Description: Collapsable side drawer that appears only for
 * authenticated users. Uses context to display extra function-
 * ality for staff and admin users. Staff links are shown in
 * gold, while student pages are shown in white. Admin pages
 * are displayed in blue.
 *
 * The drawer is semi-permanent with icons remaining on the
 * screen. The user can click the burger at the top to open
 * and view captions for the icons. On mobile, the menu is
 * completely absent from the screen to conserve space.
 *
 * MenuDrawer receives state from NavBar.js and will not
 * function without it.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AppContext from '../../helpers/AppContext';

import {
    Drawer,
    List,
    Box,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';

// load icons
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';

// navs
import StudentNav from './StudentNav';
import StaffNav from './StaffNav';
import AdminNav from './AdminNav';

const drawerWidth = 240;

// taken from material-ui mini drawer example
//    |-> https://material-ui.com/components/drawers/
const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: theme.palette.primary.main,
        overflow: "hidden",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        border: 0,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('xs')]: {
            width: theme.spacing(0),
        },     
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },       
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(8) + 11,
        },
        border: 0,

    },
    closeIcon: {
        color: theme.palette.text.primary,
    },
    navLink: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        fontFamily: "'Barlow Condensed', sans-serif",
        fontSize: 30,
    },
    icon: {
        color: theme.palette.text.primary,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    closeIconBox: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            minHeight: '64px',
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '56px',
        },
        paddingBottom: 0,
        zIndex: 1000,
        width: "275px",
        boxShadow: "0px 4px 2px -2px rgba(0,0,0,0.2)",
    },
    listSpacing: {
        paddingTop: 0,
    }
}));

function UserNavLinks(props) {
    const { staff, admin, drawerOpen, toggleDrawer } = props 
    const location = useLocation();
    const classes = useStyles();

    return (
        <List component="nav" className={classes.listSpacing}>
            <div key='nav-home'>
                <Link to='/' className={classes.navLink}>
                    <ListItem
                        button
                        key='nav-home'
                        onClick={drawerOpen ? toggleDrawer: null}
                        selected={location.pathname === '/'}
                    >
                        <ListItemIcon className={classes.icon}>
                            <HomeIcon fontSize='large' />
                        </ListItemIcon>
                        <ListItemText
                            primary='Home'
                        />
                    </ListItem>
                </Link>
            </div>
            { admin ? <AdminNav classes={classes} current={location.pathname} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} /> : null }
            { staff ? <StaffNav classes={classes} current={location.pathname} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} /> : null }
            <StudentNav classes={classes} current={location.pathname} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
        </List>
    )
}

export default function MenuDrawer(props) {
    const classes = useStyles();

    return (
        <AppContext.Consumer>
        {
            ({authenticated, userPerms, drawerOpen, toggleDrawer}) => {
                if (authenticated && userPerms !== {}) {
                    return (
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerClose]: !drawerOpen,
                            })}
                            classes={{
                                paper:clsx({
                                    [classes.drawerOpen]: drawerOpen,
                                    [classes.drawerClose]: !drawerOpen
                                }),
                            }}
                        >
                            <Box className={classes.closeIconBox} boxShadow={4}>
                                <IconButton onClick={toggleDrawer} className={classes.closeIcon}>
                                    <CloseIcon fontSize='large' />
                                </IconButton>
                            </Box>
                            <UserNavLinks staff={userPerms.staff} admin={userPerms.admin} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />  
                        </Drawer>
                    )
                }
            }
        }
        </AppContext.Consumer>
    )
}