import { Button, Card, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    addDataCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '12em',
        width: "100%",
    },
    addData: {
        height: '100%',
        width: '100%',
    }
}))

export default function AddDataCard(props) {
    let { onClick, text } = props;
    let classes = useStyles()

    return (
        <div align="center">
            <Card variant="outlined" className={classes.addDataCard}>
                <Button
                    component='span'
                    className={classes.addData}
                    onClick={onClick}
                >
                    <Typography noWrap variant="h4">
                        + Add {text}
                    </Typography>
                </Button>
            </Card>
        </div>
    )
}