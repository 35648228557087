import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    Grid,
    DialogActions,
    makeStyles,
    Paper,
    CircularProgress,
    FormControl,
    TextField,
    Link
} from '@material-ui/core';
import { CallMissedSharp } from '@material-ui/icons';
import { getTraining, listMachineGroupNames } from 'api/queries';
import { API } from 'aws-amplify';
import { Header } from 'components';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }, 
    formControl: {
        margin: theme.spacing(2),
    },
    trainingName: {
        minWidth: '50%',
    },
    trainingMachineGroup: {
        minWidth: '20%',
    },
    trainingDescription: {
        minWidth: '95%',
    },
    trainingHour: {
        minWidth: '17.5%',
        width: '17.5%'
    }
}))

async function fetchTraining(id) {
    let request = {
        query: getTraining,
        variables: {
            id: id
        }
    }

    let response = await API.graphql(request)
    return response.data.getTraining
}

async function listMachineGroups() {
    let request = {
        query: listMachineGroupNames
    }

    let response = await API.graphql(request)
    return response.data.listMachineGroups.items
}

export default function ManageTrainingsDialog(props) {
    const initData = {
        canvasId: 0,
        canvasName: "",
        name: "",
        machineGroupId: "",
        machineGroupName: "",
        description: "",
        link: "",
        published: 0
    }

    const classes = useStyles()
    const {open, id, onClose} = props;
    const [data, setData] = useState(initData)
    const [machines, setMachines] = useState([])
    const [valid, setValid] = useState(false)
    const [loading, setLoading] = useState(true)

    
    useEffect(() => {
        if (open) {
            listMachineGroups().then((result) => {
                setMachines(result)
            })
            fetchTraining(id).then((result) => {
                console.log(result)
                setData({
                    ...result,
                    link: `https://utexas.instructure.com/courses/1323730/quizzes/${result.canvasId}`,
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)
            })
        } 
    }, [open, id])

    const handleClose = () => {
        onClose();
        setData(initData);
        setLoading(true)
    }

    const handleChange = (event) => {
        let name = event.target.name
        let value = event.target.value
        if (event.target.name === 'machine-group') {

        } else {
            setData({
                ...data,
                [name]: value
            })
        }
    }

    const handleSubmit = () => {

    }

    const handlePublish = () => {

    }

    const handleDelete = () => {

    }

    return (
        <Dialog 
            open={open}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Header variant='h4' p={0} text="Manage Training" />
            </DialogTitle>
            <DialogContent>
                { loading ? <CircularProgress size={64} /> : 
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <form className={classes.root}>
                                <FormControl className={classes.trainingName}>
                                    <TextField
                                        id="training-name"
                                        label="Training Name"
                                        value={data.name}
                                        inputProps={{
                                            name: 'name'
                                        }}
                                        required
                                        onInput={handleChange}
                                    />
                                </FormControl>
                                <FormControl className={classes.trainingMachineGroup}>
                                    <TextField
                                        id="training-machine-group"
                                        label="Machine Group"
                                        // value={data.machineGroup.name}
                                        inputProps={{
                                            name: 'machine-group'
                                        }}
                                        onInput={handleChange}
                                    />
                                </FormControl>
                                <TextField
                                    id="training-description"
                                    label="Description"
                                    inputProps={{
                                        name: 'description',
                                        maxLength: 250
                                    }}
                                    value={data.description}
                                    className={classes.trainingDescription}
                                    multiline
                                    rows={4}
                                    onInput={handleChange}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper variant="outlined">
                                <Box p={2}>
                                    Canvas ID: {data.canvasId}<br />
                                    Canvas Quiz Name: {data.canvasName}<br />
                                    Canvas Link: <Link href={data.link}>/quizzes/{data.canvasId}</Link><br/>
                                    Published: {data.published ? "True" : "False"}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <ButtonGroup color="primary" variant="contained">
                    <Button disabled={!valid} onClick={handleSubmit}>
                        Update Training
                    </Button>
                    <Button disabled={!valid} onClick={handlePublish}>
                        Publish Training
                    </Button>
                    <Button color="secondary" disabled={true} onClick={handleDelete}>
                        Delete Training
                    </Button>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    )
}