/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StudentTrainings.js
 * Route: https://texasinventionworks.com/student/trainings
 * Description: This one will be a doozy. This page will act
 * as a hub for trainings. Brief Information for each training
 * will be present on the page, as well as information for
 * the current level of each student.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// import {
//     makeStyles
// } from '@material-ui/core';
import { 
    Box,
    Button,
    ButtonGroup,
    Grid, 
    Paper,
    makeStyles,
} from '@material-ui/core';
import { 
    Content, 
} from '../../../components';

const useStyles = makeStyles((theme) => ({
    root: {
        onlineButtons: {
            display: 'flex',
            justifyItems: "center"
        },
        trainingType: {
            height: '20em',
            minHeight: '20em',
        }
    },
}))

export default function StudentTrainings() {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Content pl={0} pb={0} id="student-trainings" />
            </Grid>
            <Grid item xs={12} md={6} className={classes.trainingType}>
                <Paper className={classes.trainingType}>
                    <Content id="online-trainings" />
                    <Box p={2} className={classes.onlineButtons}>
                        <ButtonGroup variant="contained" color="primary">
                            <Button
                                target="_blank"
                                href="https://forms.office.com/r/L5VLavE9dc"
                            >
                                Lasers
                            </Button>
                            <Button
                                target="_blank"
                                href="https://forms.office.com/r/6qyU9gis9G"
                            >
                                E1 Soldering
                            </Button>
                            <Button
                                target="_blank"
                                href="https://forms.office.com/r/V5KkHkEzJc"
                            >
                                3D Printing
                            </Button>
                            <Button
                                target="_blank"
                                href="https://forms.office.com/r/5pS5d9Rhxs"
                            >
                                Workshop
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} className={classes.trainingType}>
                <Paper>
                    <Content pb={0} id="in-person-trainings" />
                    <Box p={2}>
                        <ButtonGroup variant="contained" color="primary">
                            <Button 
                                target="_blank" 
                                href="https://outlook.office365.com/owa/calendar/TIWE2Training@utexas.onmicrosoft.com/bookings/">
                                Book Now!
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}