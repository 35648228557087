/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: Requests.js
 * Description: Displays service requests from students.
 * Currently only print requests are an available service,
 * but this could be refactored if other services are added
 * to the options.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {useState, useEffect} from 'react'
import { API } from 'aws-amplify'
// TODO: Create a customized listPrints query
import { getAllPrints } from '../../../../api/queries';
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Button,
    Box
} from '@material-ui/core';

import {
    makeStyles
} from '@material-ui/core/styles'

import moment from 'moment';
import {
    PaperAccent,
    Header,
} from '../../../../components/';
import { onNewPrintSubmission, onUpdatedPrintSubmission } from '../../../../api/subscriptions';

const useStyles = makeStyles((theme) => ({
    requests: {
        height: "25em",
        maxHeight: "25em",
    },
    table: {
        height: '18em',
        width: '95%',
        paddingLeft: '2.5%',
        paddingRight: '2.5%',
        paddingBottom: '1em',
        overflow: 'auto',
    },
    tableCell: {
        borderBottomColor: theme.palette.divider,
    },
    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }, title: {
        display: "flex",
        justifyContent: "space-between"
    }, newPrint: {
        height: "3em",
        marginTop: theme.spacing(2),
    }
}))

// Sorting helpers for requets. We want the requests to be sorted with newest at
// the bottom. 

async function getRequests() {
    const request = {
        query: getAllPrints,
        variables: {
            filter: {
                and: {
                    printStatus: {ne: "NEEDS_VERIFICATION"}, 
                    and: {
                        printStatus: {ne: "COMPLETE"}, 
                        and: {
                            printStatus: {ne: "CANCELLED"}, 
                            and: {
                                printStatus: {ne: "PICKED_UP"}
                            }
                        }
                    }
                }
            }
        }
    }

    let response = await API.graphql(request);
    response = response.data.listPrints.items.map((request) => {
        return {
            memberId: request.memberId,
            name: request.name,
            status: request.printStatus,
            createdAt: request.createdAt,
            submittedMoment: moment(request.createdAt).fromNow(),
            id: request.id,
        }
    });

    // Sort so oldest submitted Print is displayed first.
    response = response.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
            return -1;
        }
        if (a.createdAt > b.createdAt) {
            return 1;
        }
        return 0;
    })
    return response;
}

export default function Requests(props) {
    const { setManagePrintDialog, setNewPrintDialog, setSearchPrint} = props
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const classes = useStyles();

    const newPrintSubscription = {
        query: onNewPrintSubmission
    }

    const updatedPrintSubscription = {
        query: onUpdatedPrintSubmission
    }

    const handleManagePrint = (printId) => {
        setSearchPrint(printId);
        setManagePrintDialog(true);
    }

    const handleNewPrint = () => {
        setNewPrintDialog(true);
    }

    useEffect(() => {
        let newSubscriber = API.graphql(newPrintSubscription).subscribe({
            next: ({provider, value}) => {
                getRequests().then((result) => {
                    setRequests(result);
                }).catch((err) => {
                    console.log("Error: ", err)
                })
            }
        })
        let updateSubscriber = API.graphql(updatedPrintSubscription).subscribe({
            next: ({provider, value}) => {
                getRequests().then((result) => {
                    setRequests(result);
                }).catch((err) => {
                    console.log("Error: ", err)
                })
            }
        });
        getRequests().then((result) => {
            setRequests(result);
            setLoading(false);
        }).catch((err) => {
            console.log("Error getting Request: ", err);
        })

        return function cleanup() {
            newSubscriber.unsubscribe();
            updateSubscriber.unsubscribe();
        }
    }, []);

    return (            
        <Paper className={classes.requests}>
            <PaperAccent loading={loading} />
            <Box className={classes.title} pr={11}>
                <Header text='Active Prints' variant='h5'/>
                <Button 
                    variant="contained" 
                    color="primary" 
                    className={classes.newPrint}
                    onClick={() => handleNewPrint()}
                >
                    New Print
                </Button>
            </Box>
            
            <TableContainer className={classes.table}>
                <Table aria-label="request-table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="left">Student EID</TableCell>
                            <TableCell className={classes.tableHeader} align='left'></TableCell>
                            <TableCell className={classes.tableHeader} align="left">Print Name</TableCell>
                            <TableCell className={classes.tableHeader} align="left">Print Status</TableCell>
                            <TableCell className={classes.tableHeader} align="left">Time Submitted</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? null : requests.map((request) => {
                            return (
                                <TableRow key={request.id}>
                                    <TableCell className={classes.tableCell} key={`member-${request.id}`}>{request.memberId}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Button 
                                            variant='contained'     
                                            color='primary' 
                                            printId={request.id}
                                            onClick={() => handleManagePrint(request.id)}
                                        >
                                            Manage Print
                                        </Button>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} key={`print-name-${request.id}`}>{request.name}</TableCell>
                                    <TableCell className={classes.tableCell} key={`print-status-${request.id}`}>{request.status}</TableCell>
                                    <TableCell className={classes.tableCell} key={`print-date-${request.id}`}>{request.submittedMoment}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper> 
    )
}