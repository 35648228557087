/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StaffNav.js
 * Description: This file contains the code for the Staff
 * navigation options in the Menu Drawer.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { 
    ListItem,
    ListItemIcon,
    ListItemText, 
} from '@material-ui/core';
import {
    Link,
} from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';

// The possible routes that staff can reach.
const staffRoutes = [
    {
        title: "Admin",
        key: "nav-admin",
        icon: <SettingsIcon fontSize='large' color="secondary" />,
        path: "/admin",
    }
];

export default function AdminNav(props) {
    const {classes, drawerOpen, toggleDrawer, current} = props;

    return (
        <div id="staff-nav">
            {staffRoutes.map((route) => {
                return (
                    <div key={route.key}>
                        <Link to={route.path} className={classes.navLink}>
                            <ListItem
                                button  
                                key={route.key}
                                onClick={drawerOpen ? toggleDrawer : null}
                                selected={ current === route.path }
                            >
                                <ListItemIcon className={classes.icon} color="secondary">
                                    {route.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={route.title}
                                />
                            </ListItem>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}