/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: ToolCheckout.js
 * Description: Simple tool checkout form. EID must be exact,
 * as well as the code for the tool.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Paper, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useState } from "react";
import { Header } from "components";

import PaperAccent from "components/PaperAccent";
import AppContext from "helpers/AppContext";
import checkOutTool from "helpers/checkOutTool";
import { getToolId, parseToolName } from "helpers";

const useStyle = makeStyles((theme) => ({
  toolCheckout: {
    height: "15em",
    maxHeight: "15em",
  },
  form: {
    padding: 0,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: "center",
    "& > *": {
      width: "100%",
    },
  },
}));

export default function ToolCheckout() {
  const classes = useStyle();
  const [eid, setEid] = useState("");
  const [toolId, setToolId] = useState("");
  const { setErrState } = useContext(AppContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    getToolId(toolId.toUpperCase().trim())
      .then((r) => {
        checkOutTool("tiw", eid, r)
          .then((result) => {
            setEid("");
            setToolId("");
            setErrState({
              severity: "success",
              open: true,
              text: `Successfully Checked Out Tool: ${r}`,
            });
          })
          .catch((err) => {
            console.log(err);
            setErrState({
              severity: "error",
              open: true,
              text: err.message,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        setErrState({
          severity: "error",
          open: true,
          text: err.message,
        });
      });
  };

  return (
    <div>
      <Paper className={classes.toolCheckout}>
        <PaperAccent />
        <Header variant="h5" text="Tool Checkout" />
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <TextField
            id="checkout-member-eid"
            label="Member EID"
            value={eid}
            margin="dense"
            variant="outlined"
            size="small"
            onInput={(e) => setEid(e.target.value)}
          />
          <TextField
            id="checkout-tool-id"
            label="Tool ID"
            value={toolId}
            margin="dense"
            size="small"
            variant="outlined"
            onInput={(e) => setToolId(parseToolName(e.target.value.toUpperCase()))}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <br />
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            Checkout
          </Button>
        </form>
      </Paper>
    </div>
  );
}
