/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: LoginDialog.js
 * Description: Popup dialog for signing in. Designed to be
 * called by any login button. Prompts the user to log in
 * using either their EID or login/pass for the staff
 * TIW account.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import signIn from "../../helpers/SignIn";
import getUserPermissions from "../../helpers/getUserPermissions";
import getLoggedInMember from "../../helpers/getLoggedInMember";
import AppContext from "../../helpers/AppContext";
import parseUsername from "../../helpers/parseUsername";

const useStyles = makeStyles((theme) => ({
  dialog: {
    justifyContent: "center",
  },
  button: {
    borderRadius: 0,
    marginBottom: 20,
    width: "100%",
    fontSize: 16,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginBottom: -12,
  },
  accordionTitle: {
    display: "flex",
    fontSize: 16,
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.main,
  },
  accordianContent: {
    justifyContent: "center",
  },
  accordion: {
    width: "100%",
    marginBottom: 20,
    borderWidth: 0,
    justifyContent: "center",
    boxShadow: "none",
  },
  form: {
    padding: 0,
    justifyContent: "center",
    "& > *": {
      width: "100%",
    },
  },
}));

// TODO: Handle EID login
function LoginDialog(props) {
  const classes = useStyles();
  const { setUserPerms, setLoggedInMember, setErrState } =
    useContext(AppContext);
  const { onClose, open } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setLoading(false);
    onClose();
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let credentials = {
      user: username,
      pass: password,
    };
    signIn(credentials)
      .then((result) => {
        console.log(result);
        let userGroups = getUserPermissions(result);
        let eid = parseUsername(result.username);
        setUserPerms(userGroups);
        getLoggedInMember(eid)
          .then((result) => {
            setLoggedInMember(result);
          })
          .catch((err) => {
            console.log("Could not fetch member profile: ", err);
          });
        handleClose();
      })
      .catch((err) => {
        // Todo: notify incorrect password, etc on error
        setLoading(false);
        setErrState({
          severity: "error",
          open: true,
          text: `Cannot Sign in: ${err.message}`,
        });
      });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="login-dialog"
      open={open}
    >
      <DialogTitle id="login-dialog" variant="h3">
        Please Login to Continue
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8}>
            {/* <Button 
                            variant="contained" 
                            size="large" 
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                Auth.federatedSignIn({ provider: "utauth" });
                            }}
                        >
                            Login using EID
                        </Button> */}
          </Grid>
          <Grid item xs={12} sm={8}>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                className={classes.accordionTitle}
                classes={{ content: classes.accordianContent }}
              >
                Staff Login
              </AccordionSummary>
              <AccordionDetails
                style={{
                  marginBottom: 20,
                  border: 0,
                  justifyContent: "center",
                }}
              >
                <form
                  noValidate
                  autoComplete="off"
                  className={classes.form}
                  onSubmit={handleSubmit}
                >
                  <TextField
                    id="staff-username"
                    label="User Name"
                    value={username}
                    fullWidth
                    disabled={loading}
                    margin="normal"
                    variant="outlined"
                    onInput={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    id="staff-password"
                    label="Password"
                    value={password}
                    fullWidth
                    disabled={loading}
                    margin="normal"
                    variant="outlined"
                    type="password"
                    onInput={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    type="submit"
                    disabled={loading}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={48}
                      className={classes.buttonProgress}
                    />
                  )}
                </form>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default LoginDialog;
