import {
    Typography,
    Link,
    withStyles
} from '@material-ui/core'

const markdownStyles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
});

export const markdownOptions = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h5' } },
    h3: { component: Typography, props: { gutterBottom: false, variant: 'h6' } },
    h4: { component: Typography, props: { gutterBottom: true, variant: 'subtitle'} },
    h5: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    li: {
      component: withStyles(markdownStyles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};
