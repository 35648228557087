/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: SignIn.js
 * Description: Simple helper function to call Auth.signIn()
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Amplify, { Auth } from 'aws-amplify';

async function signIn(credentials) {
    const { user, pass } = credentials;
    let creds = await Auth.signIn(user, pass)
    Auth.currentCredentials().then((credentials) => {
        Amplify.configure({
            aws_appsync_authenticationType: credentials.authenticated ?
            "AMAZON_COGNITO_USER_POOLS" :
            "AWS_IAM"
        })
    })
    return creds
}

export default signIn;