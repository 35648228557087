/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: loggedInMember.js
 * Description: Helper function that gets the profile inform-
 * ation of the currently logged in member. This information
 * is set into the AppContext on login or page load if the
 * user is already authenticated from a previous session.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { getMemberProfile } from '../api/queries';
import { API } from 'aws-amplify';
import newMember from './newMember';

async function fetchMemberProfile(member) {
    let request = {
        query: getMemberProfile,
        variables: {
            eid: member,
        }
    }
    let response = await API.graphql(request);
    if (response.data.getMember == null) {
        try {
            response = await newMember();
        } catch (err) {
            console.log("Cannot create user:", err)
        }
    }
    return response.data.getMember;
}

export default async function getLoggedInMember(member) {
    return await fetchMemberProfile(member);
}