import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { listMaterials } from "api/queries";
import { API } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { createMaterial, updateMaterial } from "api/mutations";
import AppContext from "helpers/AppContext";
import { Header } from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "35em",
  },
  editToolbar: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  materialCharacteristics: {
    paddingRight: theme.spacing(1),
    width: "10em",
  },
  toolbarButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(2),
  },
  searchField: {
    marginLeft: "auto",
  },
  editStatusBox: {
    width: "100%",
    height: "auto",
    marginTop: theme.spacing(1),
  },
  editStatusField: {
    width: "100%",
    height: "20em",
  },
  materialBox: {
    display: "flex",
    width: "100%",
    height: "30em",
  },
}));

async function getMaterials(id) {
  let request = {
    query: listMaterials,
    variables: {
      materialId: id,
    },
  };

  let response = await API.graphql(request);
  return response.data.listMaterials.items;
}

async function createNewMaterial(inputVars) {
  let request = {
    query: createMaterial,
    variables: {
      input: inputVars,
    },
  };

  let response = await API.graphql(request);
  return response.data.createMaterial;
}

async function modifyMaterial(inputVars) {
  let request = {
    query: updateMaterial,
    variables: {
      input: inputVars,
    },
  };

  let response = await API.graphql(request);
  return response.data.updateMaterial;
}

function RowMenuCell(props) {
  const { api, id } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const isInEditMode = api.getRowMode(id) === "edit";

  const handleEditClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, "edit");
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    api.commitRowChange(id);
    let row = api.getRow(id);
    console.log(row);

    setLoading(true);
    let inputVars = {
      id: id,
      name: row.name,
      price: row.price,
      units: row.units,
    };
    modifyMaterial(inputVars)
      .then((result) => {
        api.setRowMode(id, "view");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, "view");
  };

  if (isInEditMode) {
    return (
      <div className={classes.editToolbar}>
        {loading ? (
          <CircularProgress />
        ) : (
          <IconButton
            color="primary"
            size="small"
            aria-label="save"
            onClick={handleSaveClick}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
        )}
        <IconButton
          color="inherit"
          size="small"
          aria-label="cancel"
          className={classes.textPrimary}
          onClick={handleCancelClick}
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }
  return (
    <div className={classes.editToolBar}>
      <IconButton
        color="inherit"
        size="small"
        aria-label="edit"
        onClick={handleEditClick}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton
        color="inherit"
        size="small"
        aria-label="edit"
        onClick={handleDeleteClick}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

function EditToolbar(props) {
  const { refresh, setRefresh, searchText, onSearchChange, clearSearch } =
    props;
  const { setErrState } = useContext(AppContext);
  const initData = {
    name: "",
    price: 0.0,
    units: 0,
  };
  const [add, setAdd] = useState(false);
  const [data, setData] = useState(initData);
  const classes = useStyles();

  const handleAdd = () => {
    setAdd(true);
  };

  const handleSave = () => {
    let inputVars = {
      name: data.name,
      price: data.price,
      units: data.units,
    };
    if (data.name === "") {
      setErrState({
        severity: "error",
        open: true,
        text: "Material must have a valid name!",
      });
      return;
    }

    createNewMaterial(inputVars)
      .then((result) => {
        setRefresh(refresh ? 0 : 1);
        setAdd(false);
        setData(initData);
      })
      .catch((err) => {
        console.log(err);
        setErrState({
          severity: "error",
          open: true,
          text: "Unable to create material.",
        });
      });
  };

  const handleCancel = () => {
    setAdd(false);
    setData(initData);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <GridToolbarContainer className={classes.toolbar}>
      {add ? (
        <div>
          <FormControl className={classes.materialCharacteristics}>
            <TextField
              id="material-name"
              label="Name"
              value={data.name}
              inputProps={{
                name: "name",
              }}
              size="small"
              // variant="outlined"
              onInput={handleChange}
            />
          </FormControl>
          <FormControl className={classes.materialCharacteristics}>
            <TextField
              id="material-price"
              label="Price"
              value={data.price}
              inputProps={{
                name: "price",
              }}
              size="small"
              // variant="outlined"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl className={classes.materialCharacteristics}>
            <TextField
              id="material-units"
              label="Units"
              value={data.units}
              inputProps={{
                name: "units",
              }}
              size="small"
              // variant="outlined"
              onInput={handleChange}
            />
          </FormControl>
        </div>
      ) : null}
      <Button
        color="primary"
        size="small"
        startIcon={add ? <SaveIcon /> : <AddIcon />}
        onClick={add ? handleSave : handleAdd}
        className={classes.toolbarButton}
        variant="contained"
      >
        {add ? "Save Material" : "Add Material"}
      </Button>
      {add ? (
        <Button
          className={classes.toolbarButton}
          color="primary"
          size="small"
          startIcon={<CancelIcon />}
          onClick={handleCancel}
          variant="contained"
        >
          Cancel
        </Button>
      ) : null}
      <TextField
        className={classes.searchField}
        variant="standard"
        placeholder="Search..."
        value={searchText}
        autoFocus
        onChange={onSearchChange}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              size="small"
              style={{ visibility: searchText ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </GridToolbarContainer>
  );
}

export default function MaterialTable(props) {
  const { setErrState } = useContext(AppContext);
  const classes = useStyles();
  const [materials, setMaterials] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    setRows([]);
    getMaterials(materials)
      .then((result) => {
        setMaterials(result);
        setRows(result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrState({
          severity: "error",
          open: true,
          text: "Unable to retrieve materials for.",
        });
        console.log(err);
      });
  }, [refresh]);

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = materials.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field] != null) {
          return searchRegex.test(row[field].toString());
        } else {
          return null;
        }
      });
    });
    setRows(filteredRows);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      editable: "true",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      // renderEditCell: renderStatusEditInput,
      editable: "true",
      flex: 1,
    },
    {
      field: "units",
      headerName: "Units",
      flex: 1,
      editable: "true",
    },
    {
      field: "actions",
      headerName: "Edit",
      renderCell: RowMenuCell,
      sortable: false,
      flex: 0.5,
      headerAlign: "center",
      filterable: false,
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.title}>
        <Header text="Materials" variant="h4" />
      </Box>
      <Box className={classes.materialBox} p={2}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          pageSize={50}
          loading={loading}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: {
              refresh,
              setRefresh,
              searchText: searchText,
              onSearchChange: (e) => requestSearch(e.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </Box>
    </Paper>
  );
}
