/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: getUserPermissions.js
 * Description: Helper function to discern which groups the
 * user belongs to on Cognito. This dictates what information
 * can be accessed on the site.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default function getUserPermissions(token) {
    let userGroups = {
        student: false,
        staff: false,
        admin: false,
        faculty: false,
    };
    
    let cognitoGroups = token.signInUserSession.accessToken.payload['cognito:groups'];
    for (const i in cognitoGroups) {
        switch (cognitoGroups[i]) {
            case 'Student':
                userGroups.student = true;
                break;
            case 'Staff':
                userGroups.staff = true;
                break;
            case 'Admin':
                userGroups.admin = true;
                break;
            case 'Faculty':
                userGroups.faculty = true;
                break;
            default:
                break;
        }
    }
    return userGroups;
}