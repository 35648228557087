/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: AppContext.js
 * Description: Creates a Context for use throughout the App.
 *
 * App.js wraps the entire App in a context provider. Other
 * modules can access this by wrapping their code in Consumer
 * tag or by using the useContext() hook provide by the react
 * API. More information about contexts can be found here:
 * https://reactjs.org/docs/context.html
 *
 * Context use should be limited to member information and
 * authorization. In general, information should be passed
 * through to child objects using props.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createContext } from "react";

const AppContext = createContext(null);

export default AppContext;