import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Button,
  TextField,
  makeStyles,
  Grid,
  Paper,
  Box,
  Card,
  CardMedia,
  Divider,
  CardActions,
} from "@material-ui/core";
import { API, Storage } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import {
  addImageToContent,
  modifyContent,
  setPreviewImage,
} from "../../api/mutations";
import Header from "../Header";
import ReactMarkdown from "markdown-to-jsx";
import { markdownOptions } from "./markdownOptions";
import { AppContext } from "helpers";

let imageBucket;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  imageBucket = "https://portal-prints165204-dev.s3.amazonaws.com/public/";
} else {
  imageBucket =
    "https://portal-prints113547-production.s3.amazonaws.com/public/";
}

const useStyles = makeStyles((theme) => ({
  formRoot: {
    "& > *": {
      margin: theme.spacing(1),
    },
    height: "35em",
  },
  contentTitle: {
    width: "50%",
  },
  contentSubtitle: {
    width: "95%",
  },
  contentText: {
    minWidth: "95%",
    fontFamily: "monospace",
  },
  formPane: {
    height: "35em",
    overflow: "scroll",
  },
  previewPane: {
    height: "35em",
    overflow: "scroll",
  },
  imageCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "10em",
    width: "10em",
  },
  imageGrid: {
    width: "95%",
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
  },
  cardMedia: {
    height: "100%",
    width: "auto",
  },
}));

async function updateContent(content) {
  const request = {
    query: modifyContent,
    variables: {
      input: {
        id: content.id,
        title: content.title,
        subtitle: content.subtitle,
        mainText: content.mainText,
      },
    },
  };

  let response = await API.graphql(request);
  return response.data.updateContent;
}

async function addImage(id, images) {
  const request = {
    query: addImageToContent,
    variables: {
      input: {
        id: id,
        images: images,
      },
    },
  };

  let response = await API.graphql(request);
  return response.data.updateContent;
}

async function changePreviewImage(id, image) {
  const request = {
    query: setPreviewImage,
    variables: {
      input: {
        id: id,
        previewImage: image,
      },
    },
  };

  let response = await API.graphql(request);
  return response.data.updateContent;
}

export default function EditContentDialog(props) {
  const initState = {
    id: "",
    title: "",
    subtitle: "",
    displaySubtitle: 0,
    mainText: "",
    previewText: "",
    images: [],
    previewImage: "",
  };
  const { content, setContent, open, onClose, id } = props;
  const { setErrState } = useContext(AppContext);
  const [state, setState] = useState(initState);
  const classes = useStyles();

  useEffect(() => {
    setState({ ...content });
  }, [content]);

  const handleUpdate = () => {
    updateContent(state)
      .then((result) => {
        setContent({ ...result });
        onClose(true);
        setErrState({
          severity: "success",
          open: true,
          text: `Successfully updated ${state.title}`,
        });
      })
      .catch((err) => {
        setErrState({
          severity: "error",
          open: true,
          text: `An error occurred when trying to update content`,
        });
        console.log(err);
      });
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    let newName = `images/${Date.now()}_-_${file.name}`;
    Storage.put(newName, file)
      .then((result) => {
        state.images.push(newName);
        addImage(id, state.images)
          .then((result) => {
            // console.log("Upload success!");
          })
          .catch((err) => {
            console.log("error updating images:", err);
          });
        setState({
          ...state,
        });
      })
      .catch((err) => {
        console.log("Upload failed:", err);
      });
  };

  const handlePreviewUpload = (e) => {
    const file = e.target.files[0];
    let newName = `images/preview-${state.id}`;
    Storage.put(newName, file)
      .then((result) => {
        changePreviewImage(id, newName)
          .then((result) => {
            // console.log("Upload success")
          })
          .catch((err) => {
            console.log("error update images", err);
          });
        setState({
          ...state,
          previewImage: newName,
        });
      })
      .catch((err) => {
        console.log("Upload failed:", err);
      });
  };
  if (open) {
    return (
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          <Header variant="h4" p={0} text="Edit Content" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              <Header variant="h5" text="Edit" />
              <Paper>
                <form className={classes.formRoot}>
                  <TextField
                    className={classes.contentTitle}
                    id="content-title"
                    label="Title"
                    value={state.title}
                    inputProps={{
                      name: "title",
                    }}
                    onInput={handleChange}
                  />
                  <TextField
                    className={classes.contentSubtitle}
                    id="content-subtitle"
                    label="Subtitle"
                    value={state.subtitle}
                    rows={2}
                    multiline
                    inputProps={{
                      name: "subtitle",
                    }}
                    onInput={handleChange}
                    helperText={
                      "Subtitle is used for generating page previews."
                    }
                  />
                  <TextField
                    className={classes.contentText}
                    id="content-main-text"
                    label="Main Text"
                    value={state.mainText}
                    rows={15}
                    multiline
                    inputProps={{
                      name: "mainText",
                      style: {
                        fontFamily: "monospace",
                      },
                    }}
                    helperText={`This field accepts markdown. To learn more about markdown visit https://www.markdownguide.org/basic-syntax/`}
                    onInput={handleChange}
                  />
                </form>
              </Paper>
            </Grid>
            <Grid item lg={6} md={12}>
              <Header variant="h5" text="Preview" />
              <Paper>
                <Box p={2} className={classes.previewPane}>
                  <Header
                    variant="h3"
                    pl={0}
                    pt={0}
                    text={state.title ? state.title : ""}
                  />
                  <ReactMarkdown options={markdownOptions}>
                    {state.mainText}
                  </ReactMarkdown>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Header variant="h5" text="Images" />
                <Grid container spacing={2} className={classes.imageGrid}>
                  <Grid item xs={2}>
                    <Card variant="outlined" className={classes.imageCard}>
                      {state.previewImage ? (
                        <CardMedia
                          className={classes.cardMedia}
                          image={`${imageBucket}${state.previewImage}`}
                        />
                      ) : null}
                      <CardActions>
                        <label htmlFor="upload-preview-photo">
                          <input
                            style={{ display: "none" }}
                            id="upload-preview-photo"
                            name="upload-preview-photo"
                            type="file"
                            onChange={handlePreviewUpload}
                          />
                          <Button component="span">
                            {state.previewImage
                              ? "Replace Preview Image"
                              : "Add Preview Image"}
                          </Button>
                        </label>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Divider orientation="vertical" light />
                  {state.images.map((image) => {
                    return (
                      <Grid item xs={2}>
                        <Card variant="outlined" className={classes.imageCard}>
                          <CardMedia
                            className={classes.cardMedia}
                            image={`${imageBucket}${image}`}
                          />
                          <CardActions>
                            <Button
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `![Image Text](${imageBucket}${image})`
                                );
                              }}
                            >
                              Copy Markdown
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
                  <Grid item xs={2}>
                    <label htmlFor="upload-new-photo">
                      <input
                        style={{ display: "none" }}
                        id="upload-new-photo"
                        name="upload-new-photo"
                        type="file"
                        onChange={handleUpload}
                      />
                      <Button component="span">
                        <Card variant="outlined" className={classes.imageCard}>
                          <div align="center">+ Add Image</div>
                        </Card>
                      </Button>
                    </label>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonGroup color="primary" variant="contained">
            <Button onClick={handleUpdate}>Update</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
