import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    Grid,
    DialogActions,
    makeStyles,
    TextField,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@material-ui/core';
import { API } from 'aws-amplify'
import { useState, useContext } from 'react'
import { Header } from 'components'
import AppContext from 'helpers/AppContext'
import formatStatus from 'helpers/formatStatus'
import { createProject } from 'api/mutations';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }, 
    formControl: {
        margin: theme.spacing(2),
    },
    projectName: {
        minWidth: '25%',
    },
    projectStatus: {
        minWidth: '20%',
    },
    projectDescription: {
        minWidth: '95%',
    },
}))

const PROJECTSTATUS = [
    'IN_PROGRESS',
    'COMPLETED'
]

async function submitProject(inputVars) {
    const request = {
        query: createProject, 
        variables: {
            input: inputVars
        }
    }

    let response = await API.graphql(request)
    return response.data.createProject
}

export default function NewProjectDialog(props) {
    const initProject = {
        id: '',
        name: '', 
        description: '',
        status: "IN_PROGRESS",
        projectStatus: 0
    }

    const { loggedInMember, userPerms, setErrState } = useContext(AppContext)
    const { open, onClose, checkIn } = props
    const [ project, setProject ] = useState(initProject)
    const [ valid, setValid ] = useState(false)
    const classes = useStyles()

    const handleClose = () => {
        setProject(initProject)
        onClose()
    }

    const handleSubmit = () => {
        const inputVars = {
            name: project.name, 
            ownerId: loggedInMember.eid,
            description: project.description,
            status: "IN_PROGRESS"
        }
        submitProject(inputVars).then((result) => {
            console.log(result)
            setErrState({
                severity: 'success',
                open: true, 
                text: 'Sucessfully added Project'
            })
            checkIn('project', result.id)
            handleClose()
        }).catch((err) => {
            setErrState({
                severity: 'error',
                open: true,
                text: "That's gonna be a nah from me, dawg. Can't create Project"
            })
            console.log(err);
        })
    }

    const handleChange = (event) => {
        const name = event.target.name
        let value = event.target.value
        let v = (project.name !== "") && (project.description !== "")
        setValid(v)
        setProject({
            ...project,
            [name]: value
        })

    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            scroll="paper"
            open={open}
            aria-labelledby="print-dialog"
            onClose={handleClose}   
        >
            <DialogTitle>
                <Header variant="h4" p={0} text="New Project"/>
            </DialogTitle>

            <DialogContent>
                <Paper>
                    <Box p={2} pt={0}>
                        <form className={classes.root}>
                            <FormControl className={classes.projectName}>
                                <TextField
                                    id="project-name"
                                    label="Project Name"
                                    value={project.name}
                                    variant="outlined"
                                    inputProps={{
                                        name: 'name'
                                    }}
                                    required
                                    onInput={handleChange}/>
                            </FormControl>

                            <FormControl className={classes.projectStatus}>
                                <InputLabel id="project-status">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="project-status"
                                    id="project-status"
            
                                    label="Status"
                                    defaultValue={PROJECTSTATUS.indexOf(project.status)}
                                    inputProps={{
                                        name: 'projectStatus',
                                    }}
                                    onChange={handleChange}
                                >
                                    {PROJECTSTATUS.map((status, idx) => {
                                        return (
                                            <MenuItem value={idx} key={`print-status-selection-${idx}`}>
                                                {formatStatus(status)}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <TextField
                                id="project-description"
                                label="Project Description"
                                value={project.description}
                                inputProps={{
                                    name: 'description',
                                    maxLength: 250
                                }}
                                defaultValue={project.description}
                                className={classes.projectDescription}
                                multiline
                                rows={4}
                                onInput={handleChange}
                            />
                        </form>
                    </Box>
                </Paper>

                <DialogActions>
                    <Box>
                        <ButtonGroup color="primary" variant="contained">
                            <Button onClick={handleSubmit} disabled={!valid}>
                                Create Project
                            </Button>
                            <Button onClick={handleClose}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </Box>
                </DialogActions>


            </DialogContent>
        </Dialog>

    )

}