import { useContext, useEffect, useState } from "react";
import {
    Button,
    Grid,
    makeStyles,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    AccordionActions
} from '@material-ui/core';
import awsExports from 'aws-exports';
import { Header } from "components";
import AppContext from "helpers/AppContext";
import parseUsername from "helpers/parseUsername";
import getLoggedInMember from "helpers/getLoggedInMember";
import LoginDialog from "components/Navigation/LoginDialog";
import Amplify, {API, Auth} from "aws-amplify";
import memberCheckIn from "helpers/memberCheckIn";
import memberCheckOut from "helpers/memberCheckOut";
import { listProjectsByEid, listResearchByEid, getClass } from "api/queries";
import { createClass, updateClass } from "api/mutations";
import CreateResearchDialog from './CreateResearchDialog';
import CreateProjectDialog from './CreateProjectDialog';
import CheckInTraining from "./CheckInTraining";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
    },
    content: {
        height: "100%",
        display: "flex",
        alignContent: "center",
    },
    contentBox: {
        width: "100%",
    },
    button: {
        padding: theme.spacing(1),
        display: "flex",
        alignContent: "center",
        width: "100%",
        fontSize: 16,
    },
    buttonBox: {
        width: "100%",
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(0),
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "space-around",
    },
    accordionTitle: {
        display: 'flex',
        fontSize: 16,
        justifyContent: "center",
        textTransform: "uppercase",
        backgroundColor: theme.palette.primary.main,
    },
    accordianContent: {
        justifyContent: "center",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    accordion: {
        width: "100%",
        marginBottom: 20,
        borderWidth: 0,
        justifyContent: "center",
        boxShadow: "none",
    },
    form: {
        padding: 0,
        justifyContent: "center",
        '& > *': {
            width: '100%',
        },
    },
}))

async function getClassCount(classId) {
    const request = {
        query: getClass,
        variables: {
            classId: classId
        }
    }

    let response = await API.graphql(request)
    return response.data.getClass
}

async function createNewClass(classId) {
    const request = {
        query: createClass,
        variables: {
            input: {
                classId: classId,
                count: 1
            }
        }
    }

    let response = await API.graphql(request)
    return response.data.createClass
}

async function increaseClassCounter(classId, newCount) {
    const request = {
        query: updateClass,
        variables: {
            input: {
                classId: classId,
                count: newCount
            }
        }
    }

    let response = await API.graphql(request)
    return response.data.updateClass

}

async function getProjects(eid) {
    const request = {
        query: listProjectsByEid,
        variables: {
            ownerId: eid,
            sortDirection: 'ASC',
            limit: 25,
            nextToken: null,
        }
    }
    let response = await API.graphql(request)
    return response.data.listProjectsByEid.items
}

async function getResearch(eid) {
    const request = {
        query: listResearchByEid,
        variables: {
            ownerId: eid,
            sortDirection: 'ASC',
            limit: 25,
            nextToken: null,
        }
    }
    let response = await API.graphql(request)
    return response.data.listResearchByEid.items
}

// TODO: this needs to list classes from cognito rather than being an input
//       field
function ClassAccordion(props) {
    const {
        classes, 
        expanded, 
        handleChange,
        checkIn
    } = props
    const [courseId, setCourseId] = useState("")
    const [error, setError] = useState({
        state: false,
        errorText: ""
    })

    const courseRegex = /^[A-Z]{1,3}[0-4][0-9]{2}[A-Z]?$/g

    const handleInput = (input) => {
        input = input.toUpperCase()
        setCourseId(input)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setCourseId(courseId.toUpperCase())
        if (courseId === "") {
            setError({
                state: true,
                errorText: "Course ID cannot be empty!"
            })
        } else if (!courseId.match(courseRegex)) {
            setError({
                state: true,
                errorText: "Not a valid course ID"
            })

        } else {
            setError({
                state: false,
                errorText: ""
            })
            getClassCount(courseId).then((result) => {
                console.log(result)
                if (result == null) {
                    createNewClass(courseId).then((result) => {
                        console.log(result);
                    })
                } else {
                    increaseClassCounter(courseId, result.count + 1).then((result) => {
                        console.log(result);
                    })
                }
            });
            checkIn("class")
        }
    }

    return (
        <Box className={classes.buttonBox}>
            <Accordion expanded={expanded === 'class'} onChange={handleChange('class')} className={classes.accordion}>
                <AccordionSummary
                    id="class-header"
                    className={classes.accordionTitle}
                >
                    <Typography>Class</Typography>
                </AccordionSummary>
                <AccordionDetails style={{border: 0, justifyContent: "center"}}>
                    <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit}>
                        <TextField 
                            error={error.state}
                            id="course-name" 
                            label="Class Name Ex. 'ME302'"
                            value={courseId}
                            margin="normal" 
                            variant="outlined"
                            onInput={e => handleInput(e.target.value)}
                            helperText={error.errorText}
                        />
                        <Button 
                            type="submit"
                            variant="contained" 
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

function TrainingAccordion(props) {
    const {
        classes, 
        expanded,
        handleChange,
        checkIn
    } = props
    const [ dialogOpen, setDialogOpen ] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setDialogOpen(true);
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    return (
        <Box className={classes.buttonBox}>
            <CheckInTraining open={dialogOpen} checkIn={checkIn} />
            <Accordion expanded={expanded === 'training'} onChange={handleChange('training')} className={classes.accordion}>
                <AccordionSummary
                    id="training-header"
                    className={classes.accordionTitle}
                >
                    <Typography>Training</Typography>
                </AccordionSummary>
                <AccordionDetails style={{border: 0, justifyContent: "center"}}>
                    <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit}>
                        <Button 
                            type="submit"
                            variant="contained" 
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Check In
                        </Button>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

function ProjectAccordion(props) {
    const {
        classes, 
        projects, 
        expanded, 
        handleChange,
        checkIn
    } = props
    const [projectDialog, setProjectDialog] = useState(false);

    const onClose = () => {
        setProjectDialog(false)
    }

    const handleClick = (id) => {
        checkIn('project', id)
    }

    return (
        <Box className={classes.buttonBox}>
            <CreateProjectDialog open={projectDialog} onClose={onClose} checkIn={checkIn}/>
            <Accordion expanded={expanded === 'project'} onChange={handleChange('project')} className={classes.accordion}>
                <AccordionSummary
                    id="class-header"
                    className={classes.accordionTitle}
                    >
                    <Typography>Project</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        {projects.map((project, idx) => {
                            return (
                                <Grid item xs={12} md={6} key={`print-status-selection-${idx}`}>
                                    <Box p={2} pb={0}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => handleClick(project.id)}
                                        >
                                            {project.name}
                                        </Button>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
                <AccordionActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setProjectDialog(true)}
                    >
                        Create New Project
                    </Button>
                </AccordionActions>
            </Accordion>
        </Box>
    )
}

function ResearchAccordion(props) {
    const {
        classes, 
        research, 
        expanded, 
        handleChange,
        checkIn
    } = props
    const [researchDialog, setResearchDialog] = useState(false);

    const onClose = () => {
        setResearchDialog(false);
    }

    const handleClick = (id) => {
        checkIn('research', id)
    }

    return (
        <Box className={classes.buttonBox}>  
        <CreateResearchDialog open={researchDialog} onClose={onClose} checkIn={checkIn} />
            <Accordion expanded={expanded === 'research'} onChange={handleChange('research')} className={classes.accordion}>
                <AccordionSummary
                    id="class-header"
                    className={classes.accordionTitle}
                >
                    <Typography>Research</Typography>
                </AccordionSummary>
                <AccordionDetails style={{border: 0, justifyContent: "center"}}>
                    <Grid container>
                        {research.map((r, idx) => {
                            return(
                                <Grid item xs={12} md={6} key={`print-status-selection-${idx}`}>
                                    <Box p={2} pb={0}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => handleClick(r.id)}
                                        >
                                            {r.name}
                                        </Button>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
                <AccordionActions>
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={() => setResearchDialog(true)}
                    >
                        Create New Research
                    </Button>
                </AccordionActions>
            </Accordion>
        </Box>
    )
}

export default function MemberCheckIn() {
    const classes = useStyles()
    const { setHideNavBar,
        loggedInMember,
        setLoggedInMember,
    } = useContext(AppContext)
    const [loggedIn, setLoggedIn] = useState(true)
    const [checkedIn, setCheckedIn] = useState(false)
    const [loading, setLoading] = useState(true)
    const [projects, setProjects] = useState([])
    const [research, setResearch] = useState([])
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    
    const handleLoginClose = () => {
        Auth.currentAuthenticatedUser().then((result) => {
            setLoggedIn(true)
            const eid = parseUsername(result.username)
            getLoggedInMember(eid).then((result) => {
                setLoggedInMember(result)
            })
            getProjects(eid).then((result) => {
                setProjects(result)
            }).catch((err) => {
                console.log(err)
            })
            getResearch(eid).then((result) => {
                setResearch(result)
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            if (err !== "The user is not authenticated")
                console.log("Unable to log in: ", err)
        })
    }

    const checkInHandler = (reason, id) => {
        memberCheckIn(loggedInMember, loggedInMember.eid, reason, id).then(() => {
            awsExports.oauth.redirectSignIn = "https://texasinventionworks.com/";
            Amplify.configure(awsExports)
            setHideNavBar(false)
            window.location.href = `https://texasinventionworks.com/`
        })
    }

    const checkIn = (reason, id) => {
        checkInHandler(reason, id)
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((result) => {
            setLoggedIn(true)
            const eid = parseUsername(result.username)
            getLoggedInMember(eid).then((result) => {
                setLoggedInMember(result)
            })
        }).catch((err) => {
            setLoggedIn(false)
        });
        handleLoginClose();
        if (loggedInMember.checkInStatus) {
            setCheckedIn(true)
            memberCheckOut(loggedInMember, loggedInMember.eid).then((result) => {
            console.log(result)
            }).catch((err) => {
                console.log("error")
            })
            setTimeout(() => {
                    window.location.href = "https://texasinventionworks.com/"
                }, 3000)
        } else {
            awsExports.oauth.redirectSignIn = "https://texasinventionworks.com/member-check-in";
            Amplify.configure(awsExports)
            setHideNavBar(true)
        }
        setLoading(false)
    }, [])

    if (!loading && !checkedIn) {
        return (
            <Grid container justifyContent='center' className={classes.root}>
                <LoginDialog open={!loggedIn} onClose={handleLoginClose} />
                <Grid item xs={12} sm={10} md={8} lg={6} className={classes.content}>
                    <Box className={classes.contentBox}>
                        <Header pl={0} variant="h4" text="Welcome" />
                        <Typography paragraph gutterBottom>
                            Welcome to Texas Inventionworks! What are you here to do?
                        </Typography>
                        <ClassAccordion 
                            classes={classes} 
                            loading={loading} 
                            expanded={expanded} 
                            handleChange={handleChange}
                            checkIn={checkIn} 
                        />
                        <ProjectAccordion 
                            classes={classes} 
                            loading={loading} 
                            projects={projects} 
                            expanded={expanded} 
                            handleChange={handleChange}
                            checkIn={checkIn}
                        />
                        <ResearchAccordion 
                            classes={classes} 
                            loading={loading} 
                            research={research} 
                            expanded={expanded} 
                            handleChange={handleChange}
                            checkIn={checkIn}
                        />
                        <TrainingAccordion 
                            classes={classes}
                            loading={loading}
                            expanded={expanded}
                            handleChange={handleChange}
                            checkIn={checkIn}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
    } else {
        return null
    }
}