/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StaffMembers.js
 * Route: https://texasinventionworks.com/staff/members/
 * Description: This page allows for staff to search for members
 * and view their information. It runs off of the <DataTable>
 * material component and will work with that functionality.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useContext, useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  DataGrid,
  escapeRegExp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import { MemberDialog, PageHeader, PaperAccent } from "../../../components";
import { getAllMembers } from "../../../api/queries";
import { API } from "aws-amplify";
import { formatTime } from "helpers";
import { AppContext } from "helpers";

function mapMembers(members) {
  return members.map((m) => ({
    ...m,
    id: m.eid,
    level: m.isFacultyMember
      ? "Faculty"
      : m.isAdminMember
      ? "Admin"
      : m.isStaffMember
      ? "Staff"
      : "Student",
    status: m.checkInStatus ? "Checked In" : "",
    fullName: `${m.firstName} ${m.lastName}`,
  }));
}

function getLastUpdatedMember(members) {
  let temp = [...members];
  temp.sort((a, b) => {
    var keyA = new Date(a.updatedAt),
      keyB = new Date(b.updatedAt);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  return temp[0].updatedAt;
}

async function updateMembers(members, setMembers) {
  let response;
  let lastUpdate = getLastUpdatedMember(members);
  let lim = 1000000;
  let request = {
    query: getAllMembers,
    variables: {
      limit: lim,
      filter: {
        updatedAt: {
          ge: lastUpdate,
        },
      },
    },
  };
  response = await API.graphql(request);
  let res = response.data.listMembers.items;
  res = mapMembers(res);
  if (res.length > 0) {
    let temp = [...members];
    res.forEach((e) => {
      const repIndex = temp.findIndex((m) => {
        return m.id === e.id;
      });
      temp[repIndex] = e;
    });
    setMembers(temp);
  }
}

async function listMembers(setMembers) {
  let response, nextPage;
  let members = [];
  let request = {
    query: getAllMembers,
    variables: {
      limit: 500,
      nextToken: null,
    },
  };

  do {
    response = await API.graphql(request);
    let res = response.data.listMembers.items;
    res = mapMembers(res);
    console.log("res: ", res);
    members = [...members, ...res];
    setMembers(members);
    nextPage = response.data.listMembers.nextToken;
    request.variables.nextToken = nextPage;
  } while (nextPage != null);
}

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    display: "flex",
    height: "50em",
  },
  box: {
    display: "flex",
    width: "100%",
  },
  toolbar: {
    padding: theme.spacing(1),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1),
  },
  buttons: {
    margin: theme.spacing(1),
  },
  eidBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  eidButton: {
    margin: "auto",
  },
  eidNumber: {
    margin: "auto",
  },
}));

function MemberDataToolBar(props) {
  const { classes, searchText, onSearchChange, clearSearch } = props;

  return (
    <GridToolbarContainer className={classes.toolBar}>
      <div className={classes.buttons}>
        <ButtonGroup
          color="primary"
          variant="contained"
          size="small"
        ></ButtonGroup>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </div>
      <TextField
        className={classes.textField}
        variant="standard"
        placeholder="Search..."
        value={searchText}
        autoFocus
        onChange={onSearchChange}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              size="small"
              style={{ visibility: searchText ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </GridToolbarContainer>
  );
}

export default function StaffMembers(props) {
  const [loading, setLoading] = useState(true);
  const { members, setMembers } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [eid, setEid] = useState("");
  const [memberDialog, setMemberDialog] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const classes = useStyles();
  const memberPull = useRef({
    nextToken: null,
    task: "pull",
  });

  useEffect(() => {
    if (members.length === 0) {
      listMembers(setMembers).then(() => {
        setLoading(false);
      });
    } else {
      updateMembers(members, setMembers).then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setRows(members);
  }, [members, setMembers]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = members.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field] != null) {
          return searchRegex.test(row[field].toString());
        } else {
          return null;
        }
      });
    });
    setRows(filteredRows);
  };

  const handleMemberDetails = (id) => {
    setEid(id);
    setMemberDialog(true);
  };

  const handleMemberDialogClose = () => {
    setMemberDialog(false);
    updateMembers(members, setMembers);
    setEid("");
  };

  const columns = [
    { field: "id", headerName: "EID", flex: 5 },
    {
      field: "details",
      headerName: " ",
      flex: 4,
      renderCell: (params) => {
        return (
          <div className={classes.eidButton}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(id) => handleMemberDetails(params.id)}
            >
              Details
            </Button>
          </div>
        );
      },
    },
    { field: "fullName", headerName: "Name", flex: 7 },
    { field: "email", headerName: "E-Mail", flex: 9 },
    // {field: 'phone', headerName: 'Phone', flex: 6},
    { field: "status", headerName: "Status", flex: 5 },
    { field: "level", headerName: "Level", flex: 5 },
    {
      field: "updatedAt",
      headerName: "Recent Activity",
      flex: 6,
      valueFormatter: (params) => {
        const formatted = formatTime(params);
        return `${formatted}`;
      },
    },
  ];

  return (
    <Grid container maxWidth="lg" spacing={2}>
      <MemberDialog
        open={memberDialog}
        onClose={handleMemberDialogClose}
        member={eid}
      />
      <PageHeader text="Members" />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PaperAccent loading={loading} />
          <Box p={2} className={classes.box}>
            <DataGrid
              checkboxSelection
              disableSelectionOnClick
              pageSize={50}
              columns={columns}
              rows={rows}
              sortModel={sortModel}
              isRowSelectable={(params) => params.row.id !== "tiw"}
              loading={loading}
              components={{ Toolbar: MemberDataToolBar }}
              componentsProps={{
                toolbar: {
                  classes: classes,
                  searchText: searchText,
                  onSearchChange: (e) => requestSearch(e.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
