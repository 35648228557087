import { API, Auth } from 'aws-amplify';
import { createMemberProfile } from "../api/mutations";

async function createMember(member) {
    console.log(member);
    let request = {
        query: createMemberProfile,
        variables: {
            input: {...member}
        }
    }

    let response = await API.graphql(request);
    return response.data.createMember;
} 

const trainingList = [
    {
        "name": "Laser Cutters",
        "level": 0
    },
    {
        "name": "3D Printer",
        "level": 0
    },
    {
        "name": "Workshop",
        "level": 0
    },
    {
        "name": "Othermill",
        "level": 0
    },
    {
        "name": "Vinyl Cutter",
        "level": 0
    },
    {
        "name": "E1",
        "level": 0
    },
    {
        "name": "E2",
        "level": 0
    },
    {
        "name": "E3",
        "level": 0
    }
]

const modelMember = {
    eid: '',
    email: '',
    isStaffMember: 0,
    isAdminMember: 0,
    isFacultyMember: 0,
    checkInStatus: 0,
    firstName: '',
    lastName: '',
    trainingList: trainingList,
    workstationId: 'none',
}

export default async function newMember() {
    let jwt = await Auth.currentSession()
    jwt = jwt.idToken.payload;
    let member = {...modelMember}
    let name = jwt.name.split(" ");
    member.eid = jwt["preferred_username"]
    member.email = jwt.email
    member.firstName = name[0]
    member.lastName = name[name.length - 1]

    return createMember(member);
}