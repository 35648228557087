/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: ManageAreaDialog.js
 * Description: Manage Areas within the Engineering Shop
 * 
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence, Hakeem Telsiz
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    Grid,
    DialogActions,
    makeStyles,
    CircularProgress,
} from '@material-ui/core';
import { API } from 'aws-amplify';
import { useContext, useEffect, useState  } from 'react';
import { getArea } from 'api/queries';
import { Header } from 'components';
import AreaDescription from './AreaDescription';
import AreaHours from './AreaHours';
import { updateArea } from 'api/mutations';
import AppContext from 'helpers/AppContext';
import { updateAreaTransaction } from 'helpers/transactions';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }, 
    formControl: {
        margin: theme.spacing(2),
    },
    areaName: {
        minWidth: '50%',
    },
    areaRoom: {
        minWidth: '20%',
    },
    areaDescription: {
        minWidth: '95%',
    },
    areaHour: {
        minWidth: '17.5%',
        width: '17.5%'
    }
}))

async function getAreaDetails(areaId) {
    let request = {
        query: getArea,
        variables: {
            id: areaId
        }
    }

    let response = await API.graphql(request);
    return response.data.getArea;
}

async function updateAreaDetails(inputVars) {
    let request = {
        query: updateArea,
        variables: {
            input: inputVars
        }
    }

    let response = await API.graphql(request);
    return response.data.updateArea
}

export default function ManageAreaDialog(props) {
    const { areaId, open, onClose } = props
    const [ loading, setLoading ] = useState(true);
    const { setErrState, loggedInMember } = useContext(AppContext);
    const [ area, setArea ] = useState({})
    const classes = useStyles();

    useEffect(() => {
        if (open) {
            getAreaDetails(areaId).then((result) => {
                setArea(result);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [open])

    const handleSubmit = () => {
        console.log(area)
        updateAreaDetails(area).then((result) => {
            setErrState({
                severity: 'success',
                open: true,
                text: `Succesfully updated ${result.name}`
            })
            updateAreaTransaction(loggedInMember.eid, result.id, area.name)
            .catch(err => {
                console.log("Could not create transaction: ", err.message)
            })
            onClose()
        }).catch((err) => {
            console.log(err)
            setErrState({
                severity: 'error',
                open: true,
                text: `Failed to update ${area.name}`
            })
        })
    }

    const handleClose = () => {
        setArea({})
        setLoading(true);
        onClose();
    }

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle>
                <Header variant='h4' p={0} text="Manage Area" />
            </DialogTitle>
            <DialogContent>
                { loading ? 
                <div align="center">
                    <CircularProgress size={64} />
                </div>
                : 
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <AreaDescription classes={classes} area={area} setArea={setArea} />
                    </Grid>
                    <Grid item xs={4}>
                        <AreaHours classes={classes} area={area} setArea={setArea} />
                    </Grid>
                </Grid>
            }
            </DialogContent>
            <DialogActions>
                <Box>
                    <ButtonGroup color="primary" variant='contained'>
                        <Button onClick={handleSubmit}>
                            Save Area
                        </Button>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
