/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StudentReservations.js
 * Route: https://texasinventionworks.com/student/reservations
 * Description: Page for reservation system. Students can use
 * this page to reserve lasers, workshop, craftbots, and work-
 * stations in the space.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    Grid,
    Paper,
    ButtonGroup,
    Button,
    makeStyles
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { Content, Header } from '../../../components';
import { useCallback, useEffect, useState } from 'react';
import { listReservableAreas } from '../../../api/queries';
import { API } from 'aws-amplify';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    reservationButtonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    reservationButtons: {
        margin: theme.spacing(2),
    },
    calendarPicker: {
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
    },
    slots: {
        justifyContent: "wrap",
        width: "100%",
        padding: theme.spacing(2),
    },
    timeSlot: {
        margin: theme.spacing(2),
        width: '8em'
    }
}))

async function getReservableAreas() {
    let request = {
        query: listReservableAreas,
        variables: {
            reserve: 1,
            limit: 10,
            sortDirection: "ASC"
        }
    }
    let response = await API.graphql(request);
    return response.data.listReservableAreas.items;
}
function getMidnight(date) {
    let hours = date.hours() * 3600;
    let minutes = date.minutes() * 60;
    let seconds = date.seconds();
    let result = (date.unix() - hours - minutes - seconds) * 1000;
    return result;
}

export default function StudentReservations() {
    const initState = {
        areaNames: [],
        areas: {},
        selectedArea: "",
        selectedDate: getMidnight(moment()),
        selectedDay: moment().format("dddd"),
        selectedTime: "",
        hours: {},
        slotDuration: 0,
        slots: [],
    }
    const [state, setState] = useState({...initState});
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    const generateSlots = useCallback(() => {
        if (state.selectedArea !== "") {
            console.log(state.selectedArea);
            let startTime = state.hours[state.selectedDay].openingTime;
            let endTime = state.hours[state.selectedDay].closingTime;
            if (startTime === -1 || endTime === -1) {
                setState(state => {return {
                    ...state,
                    slots: [],
                }})
                return;
            }
            let slots = [];
            while (startTime < endTime) {
                let slot = startTime + state.selectedDate.valueOf();
                let humanize = moment(slot).format('LT');
                slots.push({
                    humanized: humanize,
                    startTime: slot,
                })
                startTime += state.slotDuration;
            }
            setState(state => {return {
                ...state,
                slots: slots,
            }})
            console.log(slots);
        }
    }, [state.selectedArea, state.hours, state.selectedDay, state.selectedDate, state.slotDuration])

    const handleDateChange = (date) => {
        let newDate = getMidnight(date);
        console.log(newDate);
        setState({
            ...state,
            selectedDay: date.format('dddd'),
            selectedDate: newDate,
        })
    };

    const updateArea = (area) => {
        setState({
            ...state,
            selectedArea: area,
            hours: Object.assign({}, ...state.areas[area].hours.map(day => ({[day.day]: day}))),
            slotDuration: state.areas[area].slotDuration
        })
    }

    // set up page on first render
    useEffect(() => {
        getReservableAreas().then((result) => {
            let areaList = result.map(area => area.name);
            let areas = Object.assign({}, ...result.map(area => ({[area.name]: area})));
            setState(state => {return {
                ...state,
                areaNames: areaList,
                areas: areas
            }})
            setLoading(false);
        })
    }, []);

    // call generateSlots whenever selectedArea or Date change.
    useEffect(() => {
        generateSlots();
    }, [state.selectedArea, state.selectedDate, generateSlots])

    return (
        <div>
            <Content pl={0} pb={4} id="reservations" />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Header variant="h4" text="Make a Reservation" />
                    </Grid>
                    <Grid item sm={4} md={2} className={classes.reservationButtonBox}>
                        <ButtonGroup
                            variant="contained"
                            orientation="vertical"
                            className={classes.reservationButtons}
                        >
                            {!loading && state.areaNames.map(area => {
                                return (
                                    <Button
                                        color={
                                            state.selectedArea === area 
                                            ? 'secondary'
                                            : 'primary'
                                        }
                                        onClick={() => updateArea(area)}
                                    >
                                        {area}
                                    </Button>
                                )
                            })}
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} className={classes.calendarPicker}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                disableToolbar
                                variant="static"
                                format='MM/DD/yyyy'
                                margin="normal"
                                id="date-picker-inline"
                                label="Date picker inline"
                                disableFuture={state.selectedArea === ""}
                                disablePast
                                value={state.selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.slots}>
                        {state.slots.length > 0 ?
                        state.slots.map((slot) => {
                            return (
                                <Button className={classes.timeSlot} variant="contained" size="large">
                                    {slot.humanized}
                                </Button>
                            )
                        })
                        :
                            "Select equipment to view available time slots."
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}