import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ButtonGroup,
    Box,
    TextField,
    MenuItem,
    FormControl,
    Paper,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { Header } from "components";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    trainingBox: {
        width: "100%",
    }
}))


export default function CheckInTraining(props) {
    const { open, onClose, checkIn } = props;
    const [ text, setText ] = useState("")

    const classes = useStyles();

    const handleClose = () => {
        setText("")
        onClose()
    }

    const handleChange = (event) => {
        setText(event.target.value)
    }

    const handleSubmit = () => {
        checkIn(`training: ${text}`)
        handleClose()
    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            scroll="paper"
            open={open}
            aria-labelledby="training-check-in-dialog"
            onClose={handleClose}
        >
            <DialogTitle>
                <Header variant="h4" p={0} text="Training" />
            </DialogTitle>
            <DialogContent>
                    <Box p={2} pt={0}>
                        <Box pb={2}>
                            <Typography pb={2}>Which training are you here for?</Typography>
                        </Box>
                        <FormControl className={classes.trainingBox}>
                            <TextField
                                id="training"
                                label="Training"
                                value={text}
                                variant="outlined"
                                inputProps={{
                                    name: 'training'
                                }}
                                required
                                onInput={handleChange}
                            />
                        </FormControl>
                    </Box>
                <DialogActions>
                    <Box>
                        <ButtonGroup color="primary" variant="contained">
                            <Button onClick={handleSubmit} disabled={text === ''}>
                                Check In
                            </Button>
                            <Button onClick={handleClose}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}