/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: App.js
 * Description: Main root of the entire portal. This file is
 * used to provide Contexts, Theming, and Routing to the
 * portal. Additionally, it checks if the user is returning
 * with a login token from a previous session and grants them
 * authenticated status if so.
 *
 * All other code outside of this narrow scope should be ab-
 * stracted into other files.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Import material components
import { CircularProgress, Container, CssBaseline } from "@material-ui/core";

// Import style related
import theme from "./theme";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

// Import fonts
import "@fontsource/barlow-condensed/600.css";
import "@fontsource/roboto";

// import pages
import {
  LandingPage,
  LearnMore,
  MemberCheckIn,
  QRCode,
  Admin,
  StaffDashboard,
  StaffMembers,
  StaffPrints,
  StaffTools,
  StudentFeedback,
  StudentPrints,
  StudentReservations,
  StudentTrainings,
} from "./pages";

// import components
import { NavBar, MenuDrawer, MessageBar } from "./components";

// Import Amplify and set up
import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import AppContext from "./helpers/AppContext";

// misc react imports
import { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import getUserPermissions from "./helpers/getUserPermissions";
import getLoggedInMember from "./helpers/getLoggedInMember";
import prefetchContent from "./helpers/prefetchContent";
import parseUsername from "./helpers/parseUsername";
import PrivateRoute from "./components/PrivateRoute";
import Footer from "./components/Footer/Footer";

Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  main: {
    // display: 'flex',
    width: "100vw",
    paddingTop: 75,
    marginBottom: 25,
    flexGrow: 1,
    minHeight: "100vh",
    alignContent: "flex-start",
  },
  pageLoading: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function App() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [loggedInMember, setLoggedInMember] = useState({});
  const [hideNavBar, setHideNavBar] = useState(false);
  const [content, setContent] = useState({});
  const [members, setMembers] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userPerms, setUserPerms] = useState(false);
  const [errState, setErrState] = useState({
    severity: "error",
    open: false,
    text: "",
  });

  // Check if the user already has a valid logged in token
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        const eid = parseUsername(result.username);
        setAuthenticated(true);
        let userGroups = getUserPermissions(result);
        setUserPerms(userGroups);
        getLoggedInMember(eid)
          .then((result) => {
            setLoggedInMember(result);
            setLoading(false);
          })
          .catch((err) => {
            console.log("Could not fetch member profile: ", err);
            setLoading(false);
          });
      })
      .catch((err) => {
        setAuthenticated(false);
        setLoading(false);
      });
    prefetchContent()
      .then((result) => {
        setContent(result);
      })
      .catch((err) => {
        console.log("Error during prefetch: ", err);
        setErrState({
          severity: "error",
          open: true,
          text: "Error fetching page content, please refresh the page and try logging in again. If the error persists, contact TIW staff.",
        });
      });
  }, []);

  useEffect(() => {
    console.log(userPerms);
  }, [userPerms]);

  function toggleAuthenticated() {
    setAuthenticated((loggedIn) => !loggedIn);
  }

  function toggleDrawer() {
    setDrawerOpen((drawerOpen) => !drawerOpen);
  }

  if (!loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppContext.Provider
          value={{
            authenticated,
            drawerOpen,
            userPerms,
            loggedInMember,
            content,
            setHideNavBar,
            setContent,
            setLoggedInMember,
            toggleAuthenticated,
            toggleDrawer,
            setUserPerms,
            setErrState,
            members,
            setMembers,
          }}
        >
          <div className={classes.root}>
            <MessageBar state={errState} setState={setErrState} />
            <Router>
              {!hideNavBar ? (
                <div>
                  <NavBar />
                  <MenuDrawer />
                </div>
              ) : null}
              <Container maxWidth="lg" className={classes.main}>
                <Switch>
                  <Route
                    // exact
                    path="/"
                    component={() => {
                      window.location.href =
                        "https://inventionworks.engr.utexas.edu/";
                      return null;
                    }}
                  />
                  {/* <PrivateRoute
                    exact
                    perm={userPerms.student}
                    path="/students/prints"
                    component={StudentPrints}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.student}
                    path="/students/trainings"
                    component={StudentTrainings}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.student}
                    path="/students/reservations"
                    component={StudentReservations}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.student}
                    path="/students/feedback"
                    component={StudentFeedback}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.staff}
                    path="/staff/dashboard"
                    component={StaffDashboard}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.staff}
                    path="/staff/prints"
                    component={StaffPrints}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.staff}
                    path="/staff/members"
                    component={StaffMembers}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.staff}
                    path="/staff/tools"
                    component={StaffTools}
                  />
                  <PrivateRoute
                    exact
                    perm={userPerms.admin}
                    path="/admin"
                    component={Admin}
                  />
                  <Route exact path="/learn/:title" component={LearnMore} />
                  <Route
                    exact
                    path="/member-check-in"
                    component={MemberCheckIn}
                  />
                  <Route
                    exact
                    path="/qr-code-for-check-in"
                    component={QRCode}
                  /> */}
                </Switch>
              </Container>
              <Footer />
            </Router>
          </div>
        </AppContext.Provider>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.pageLoading}>
          <CircularProgress size={128} />
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
