/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: Area.js
 * Description: Manages the Checked In Members section of the
 * staff dashboard. Checked in members are displayed as a list
 * of Cards. Clicking on the more details button loads the
 * MemberDialog component onto the screen.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence, Hakeem Telsiz
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useState, useEffect, useContext } from "react";
import { AddDataCard, Header, PaperAccent } from "../../../components";
import { 
    Paper,
    Grid,
    Card,
    Box,
    Typography,
    Button,
    ButtonGroup,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { API } from "aws-amplify";
import { listAreas } from "api/queries";
import { CreateAreaDialog } from "./components";
import ManageAreaDialog from "./components/AreaDialogs/ManageAreaDialog";

const useStyles = makeStyles((theme) => ({
    area: {
        height: "34em",
        maxHeight: "34em",
        // overflow: 'scroll'
    },
    areaCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '12em',
        width: "100%",
        paddingTop: 20,
    },
    areaBox : {
        display: 'flex',
        overflow: 'auto',
        height: '25em',
        justifyContent: 'center',
    },
    areaGrid: {
        width: "95%",
        paddingLeft: "2.5%",
        paddingRight: '2.5%',
        // overflow: 'scroll'
    },
    detailsButton: {
        marginBottom: 'auto',
    },
    areaSummary: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    avatar: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
    },
    checkOut: {
        height: "3em",
        marginTop: theme.spacing(2),
    }
}))

async function getAreas() {
    let request = {
        query: listAreas,
        variables: {
            limit: 25
        }
    }
    let response = await API.graphql(request);
    return response.data.listAreas.items;
}

export default function Area(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [createAreaDialog, setCreateAreaDialog] = useState(false);
    const [manageAreaDialog, setManageAreaDialog] = useState(false);
    const [areaId, setAreaId] = useState("");
    const {facilities, setFacilities} = props

    useEffect(() => {
        setLoading(facilities.loading)
    }, [facilities])

    const handleAddArea = () => {
        setCreateAreaDialog(true);
    }

    const handleManageDialog = (id) => {
        setAreaId(id);
        setManageAreaDialog(true);
    }

    const handleDialogClose = () => {
        setCreateAreaDialog(false);
        setLoading(true);
        setManageAreaDialog(false);
        getAreas().then((result) => {
            setFacilities(prevValues => {
                return {
                ...prevValues,
                areas: result
            }});
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    return (
        <div>
            <CreateAreaDialog open={createAreaDialog} onClose={handleDialogClose} />
            <ManageAreaDialog open={manageAreaDialog} onClose={handleDialogClose} areaId={areaId} />
            <Paper className={classes.area}>
                <PaperAccent loading={loading} />
                <Box className={classes.title} pr={11}>
                    <Header variant="h4" text="Area" />
                </Box>
                <Box className={classes.areaBox}>
                    <Grid container spacing={2} className={classes.areaGrid}>
                        {facilities.areas.map((area) => {
                                return (
                                    <Grid item xs={12} sm={6} md={3} key={`area-${area.id}`}>
                                        <div align="center" key={`area-${area.id}`}>
                                            <Card variant="outlined" className={classes.areaCard}>
                                                <Box className={classes.areaSummary} pb={2}>
                                                    <Typography noWrap variant="h4">
                                                        {area.name}
                                                    </Typography>
                                                    <Typography noWrap variant="h5">
                                                       Room number: {area.roomNumber}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.detailsButton} pb={2}>
                                                    <ButtonGroup
                                                        color='primary'
                                                        variant='contained'
                                                        size='small'
                                                    >
                                                        <Button onClick={() => handleManageDialog(area.id)}>
                                                            Manage Area
                                                        </Button>
                                                    </ButtonGroup>
                                                </Box>
                                            </Card>
                                        </div>
                                    </Grid>
                                )
                            }
                        )}
                        <Grid item xs={12} sm={6} md={3}>
                            <AddDataCard onClick={handleAddArea} text="Area" />
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </div>
    )
}