/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: ManagePrintDialog.js
 * Description: Pop up dialog with information on specific 
 * prints and actions to move the prints down the pipe line
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Box,
    Grid,
    DialogActions,
    makeStyles,
    TextField,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@material-ui/core';
import { API, Storage } from 'aws-amplify';
import { useEffect, useState, useContext } from 'react';
import { getPrint } from '../../api/queries';
import Header from '../Header';
import moment from 'moment';
import AppContext from '../../helpers/AppContext';
import updatePrintDetails from './helpers/updatePrintDetails';
import { CloudDownload } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }, 
    formControl: {
        margin: theme.spacing(2),
    },
    printName: {
        width: '45%',
        minWidth: 180,
    },
    printStatus: {
        width: '20%',
        minWidth: 100,
    },
    printer: {
        width: '25%',
        minWidth: 120,
    },
    printDescription: {
        minWidth: '95%',
    },
    deviceName: {
        width: "60%",
    },
    printMaterial: {
        width: "30%",
    }
}))

const PRINTERS = [
    'Formlabs',
    'Dremel',
    'Crafbot XL',
    'Craftbot Plus',
    'MarkForge',
]

const PRINTSTATUS = [
    "RECEIVED",
	"VERIFIED", // Temps are exact requirements
	"STARTED",
	"ERROR",
	"PROCESSING",// Specific to SLAs
	"COMPLETE",
	"CANCELLED",
	"PICKED_UP",
]

function formatPrintStatus(status) {
    let words = status.split('_');
    for (let i in words) {
        words[i] = words[i].toLowerCase();
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    status = words.join(' ');
    return status;
}

async function getPrintDetails(printId) {
    let request = {
        query: getPrint,
        variables: {
            id: printId
        }
    }

    let response = await API.graphql(request);
    return response.data.getPrint
}

export default function ManagePrintDialog(props) {
    const initPrint = {
        name: "",
        description: "",
        printStatus: "",
        member: {
            eid: "none",
        },
        printerName: "",
        material: "",
    }

    const { loggedInMember, userPerms } = useContext(AppContext);
    const { printId, open, onClose } = props;
    const [ print, setPrint ] = useState(initPrint);
    const [ loading, setLoading ] = useState(true);
    const [ printName, setPrintName] = useState("");
    const classes = useStyles();

    const handleClose = () => {
        onClose();
        setLoading(true);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        if (name === "printStatus") {
            value = PRINTSTATUS[event.target.value].toUpperCase();
            value = value.split(' ');
            value = value.join('_');
        } else if (name === 'printerType') {
            value = PRINTERS[event.target.value];
        } else {
            value = event.target.value;
        }
        setPrint({
            ...print,
            [name]: value
        })
    }

    const handleUpdate = () => {
        updatePrintDetails(
            print.member.eid, 
            loggedInMember.eid, 
            print).then((result) => {
                setPrint(result);
        });
    }

    const handleDownload = () => {
        Storage.get(print.stlFile).then((result) => {
            const link = document.createElement('a');
            link.download = result
            link.href = result;
            link.click();
        })
    }

    // const handleCancel = () => {
    //     updatePrintCancel(printId);
    //     onClose();
    // }
    
    useEffect(() => {
        getPrintDetails(printId).then((result) => {
            setPrint(result);      
            setPrintName(result.name);
            setLoading(false);
        }).catch((err) => {
            if (err.message !== "result is null"){
                console.log(err);   
            }
        });
    }, [printId])

    return (
        <Dialog 
            maxWidth="md" 
            fullWidth={true} 
            scroll='paper' 
            open={open}
            aria-labelledby="print-dialog"
            onClose={handleClose}
        >
            <DialogTitle>
                <Header variant="h4" p={0} text="Manage Print" />
                <Header variant="h5" p={0} text={printName} />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Paper>
                        <Header variant='h5' text='Print' />
                        <Box p={2} pt={0}>
                            {!loading ?
                                <form className={classes.root} noValidate>
                                    <FormControl className={classes.printName}>
                                        <TextField 
                                            id="print-name"
                                            label="Print Name"
                                            value={print.name}
                                            disabled={loggedInMember.eid !== print.member.eid}
                                            variant='outlined'
                                            inputProps={{
                                                name: 'name',
                                            }}
                                            onInput={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.printStatus}>
                                        <InputLabel id='print-status'>Status</InputLabel>
                                        <Select
                                            labelId="print-status"
                                            id="print-status"
                                            disabled={!userPerms.staff}
                                            label="Status"
                                            variant="outlined"
                                            defaultValue={PRINTSTATUS.indexOf(print.printStatus)}
                                            inputProps={{
                                                name: 'printStatus',
                                            }}
                                            onChange={handleChange}
                                        >
                                            {PRINTSTATUS.map((status, idx) => {
                                                return (
                                                    <MenuItem value={idx} key={`print-status-selection-${idx}`}>{formatPrintStatus(status)}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.printer}>
                                        <InputLabel id="print-printer">Printer</InputLabel>
                                        <Select
                                            labelId="print-printer"
                                            id="print-printer"
                                            variant="outlined"
                                            defaultValue={PRINTERS.indexOf(print.printerType)}
                                            inputProps={{
                                                name: 'printerType',
                                            }}
                                            onChange={handleChange}
                                        >
                                            {PRINTERS.map((printer, idx) => {
                                                return (
                                                    <MenuItem value={idx} key={`print-printer-selection-${idx}`}>{printer}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.deviceName}>
                                        <TextField 
                                            id="device-name"
                                            label="Printer Name"
                                            value={print.printerName}
                                            variant="outlined"
                                            inputProps={{
                                                name: "printerName"
                                            }}
                                            onInput={handleChange}
                                            disabled={print.printerName !== null}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField 
                                            id="print-material"
                                            label="Material"
                                            value={print.material}
                                            variant="outlined"
                                            inputProps={{
                                                name: "material"
                                            }}
                                            onInput={handleChange}
                                            disabled
                                        />
                                    </FormControl>
                                    <TextField 
                                        id="print-description"
                                        label="Description"
                                        inputProps={{
                                                name: 'description',
                                                maxLength: 250,
                                            }}
                                        defaultValue={print.description}
                                        className={classes.printDescription}
                                        variant="outlined"
                                        // disabled={true}
                                        multiline
                                        rows={4}
                                        onInput={handleChange}
                                    />
                                </form>
                                :
                                null
                            }
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Paper>
                            <Header variant="h5" text="Details" />
                            {/* TODO make this a table or something better */}
                            {!loading ? 
                                <Box p={2} pt={0}>
                                    Member ID: {print.member.eid} <br />
                                    Member Name: {print.member.firstName} {print.member.lastName} <br />
                                    Submitted {moment(print.createdAt).fromNow()} <br />
                                    Last Update {moment(print.updatedAt).fromNow()} <br />
                                    <Button 
                                        variant="contained" 
                                        color="secondary"
                                        startIcon={<CloudDownload />}
                                        onClick={handleDownload}
                                        disabled={print.stlFile === null}
                                    >
                                        Download STL
                                    </Button>
                                </Box>
                                :
                                null
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box>
                    <ButtonGroup color="primary" variant="contained">
                        {/* <Button color="success" disabled={loading}>
                            Approve
                        </Button>
                        <Button 
                            className={classes.cancelButton}
                            onClick={handleCancel}
                            color="warning"
                            disabled={loading}
                        >
                            Cancel
                        </Button> */}
                        <Button onClick={handleUpdate} disabled={loading}>
                            Update
                        </Button>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogActions>
        </Dialog>   
    )
}