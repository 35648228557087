import { useContext, useEffect, useState } from "react";


import {
    Box,
    Grid,
    makeStyles,
} from '@material-ui/core';
import AppContext from "../../helpers/AppContext";
import { Content, Header } from "../../components";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.main,
        height: '101vh',
        width: '101vw',
        zIndex: 10,
        alignContent: "center",
        top: 0,
        left: 0,
        position: 'fixed',
    },
    qrCode: {
        marginTop: theme.spacing(2),
        backgroundImage: `url("/check-in-qr-code.svg")`,
        backgroundSize: '25em auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        width: '100%',
        height: '25em',
        padding: theme.spacing(10),
    }
}));

export default function QRCode() {
    const classes = useStyles();
    const { setHideNavBar } = useContext(AppContext);  
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setHideNavBar(true);
        setLoading(false);
    }, [])

    if (!loading) {
        return (
            <Grid spacing={2} container className={classes.root} justifyContent="center">
                <Grid item xs={2} />
                <Grid item xs={9}>
                    <Header variant='h1' pl={0} text="Welcome to Texas Inventionworks" />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={12} sm={10} md={2} />
                <Grid item xs={12} sm={10} md={4}>
                    <Content header='h2' pl={0} id='qr-code-check-in-content' />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box className={classes.qrCode}>
                        
                    </Box>
                </Grid>
                <Grid item md={2} />
            </Grid>
        )
    }
    return null
}