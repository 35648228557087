import {
    makeStyles,
    Container,
    Grid,
    Typography,
    Box,
    Divider,
    List,
    ListItem,
} from '@material-ui/core'

import {
    Header,
} from '../../components'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        width: "100vw",
        // height: "25em",
        paddingTop: "5em",
        paddingBottom: "5em",
    },
    cockrell: {
        background: `url("/knockout_formal_Cockrell.svg")`,
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        [theme.breakpoints.up('sm')]: {
            width: "350px",
            height: "52px",
        },
        [theme.breakpoints.down('xs')]: {
            width: "300px",
            height: "40px",
        }
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        height: '1em',
        width: "56%",
        "& a": {
            textDecoration: 'none'
        },
        "& img": {
            display: 'block',
            height: '2em',
            width: "auto",
        }
    },
    footerText: {
        "& a": {
            textDecoration: "none",
            color: theme.palette.text.primary,
        },
        "& a:hover": {
            textDecoration: "underline"
        }
    },
}))

export default function Footer(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <Box pl={1} pr={1}>
                    <Grid container className={classes.mainGrid} spacing={2}>
                        <Grid item sm={6} md={4}>
                            <div className={classes.cockrell} />
                            <Divider className={classes.divider}/>
                            <Typography variant="body2" className={classes.footerText}>
                                © The University of Texas at Austin 2021 | <a href="https://cio.utexas.edu/policies/web-privacy">Privacy Policy</a> | <a href="https://cio.utexas.edu/policies/web-accessibility">Accessibility</a>
                            </Typography>                            
                        </Grid>
                        <Grid item sm={6} md={4}>
                            <Header variant="h6" pl={0} pt={0} pb={0} text="TEXAS INVENTIONWORKS" />
                            <Box pl={0} pr={0}>
                                <Typography className={classes.footerText} paragraph>
                                    Located on Floors 0 and 1 in the National Instruments student Project center inside the Engineering Education and Research Center
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <Header variant="h6" pl={0} pt={0} text="CONNECT WITH THE COCKRELL SCHOOL" />
                            <List className={classes.social}>
                                <ListItem>
                                    <a href="https://twitter.com/cockrellschool">
                                        <img src="/Twitter-logo-ko.svg" alt="Twitter" />
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://instagram.com/cockrellschool">
                                        <img src="/Instagram-logo-ko.svg" alt="Instagram" />
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://youtube.com/user/cockrellschool">
                                        <img src="/YouTube-logo-ko.svg" alt="YouTube" />
                                    </a>
                                </ListItem>    
                                <ListItem>
                                    <a href="https://facebook.com/cockrellschool">
                                        <img src="/Facebook-logo-ko.svg" alt="Facebook" />
                                    </a>
                                </ListItem>    
                                <ListItem>
                                    <a href="https://www.linkedin.com/school/cockrell-school-of-engineering-the-university-of-texas-at-austin/">
                                        <img src="/LinkedIn-logo-ko.svg" alt="Linked In" />
                                    </a>
                                </ListItem>     
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}