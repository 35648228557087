/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: AreaHours.js
 * Description: Prop for room number hours 
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence, Hakeem Telsiz
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { 
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    TextField,
} from "@material-ui/core";
import { Header } from "components";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    hoursContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        flexWrap: "nowrap",
        justifyContent: "space-between",
    },
    hoursFields: {
        alignSelf: "flex-end",
        display: "flex"
    },
    checkboxList: {
        minWidth: "50%",
        width: "50%",
    },
    areaHour: {
        minWidth: '17.5%',
        width: '17.5%'
    }
}))
const DAYS = [
    'MONDAY',
	'TUESDAY',
	'WEDNESDAY',
	'THURSDAY',
	'FRIDAY',
	'SATURDAY',
	'SUNDAY'
]

export default function AreaHours(props) {
    const {area, setArea} = props;
    const classes = useStyles();
    
    const handleChange = (e) => {
        // TODO: add IMask for the room number!
        let name = e.target.name;
        let val = e.target.value;
        let idx = parseInt(name.slice(-1));
        let hours = [...area.hours]
        if (name.slice(0, 11) === "dayOpenTime") {
            hours[idx].openingTime = parseInt(val);
            setArea({
                ...area,
                hours: [...hours]
            })
        } else if (name.slice(0, 7) === "dayOpen") {
            val = e.target.checked ? 1 : 0;
            hours[idx].open = parseInt(val);
            setArea({
                ...area,
                hours: [...hours]
            })
        } else if (name.slice(0, 12) === "dayCloseTime") {
            hours[idx].closingTime = parseInt(val)
            setArea({
                ...area,
                hours: [...hours]
            })
        }
    }
    
    return (
        <div>
            <Header variant='h5' text="Hours" p={0} pb={2}/>
            <FormGroup>
                {area.hours.map((day, idx) => {
                    return (
                        <Box className={classes.hoursContainer} p={0} pb={2}>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        size="small"
                                        inputProps={{
                                            name: `dayOpen-${idx}`,
                                        }}
                                        checked={day.open == 1}
                                        color="primary"
                                        onClick={handleChange}
                                    />
                                } label={DAYS[idx]} 
                                className={classes.checkboxList}
                            />
                            <TextField
                                className={classes.areaHour}
                                variant="outlined"
                                label="Open"
                                size='small'
                                inputProps={{
                                    name: `dayOpenTime-${idx}`,
                                }}
                                value={day.openingTime}
                                onChange={handleChange}
                                disabled={day.open == 0}
                            />
                            <TextField
                                className={classes.areaHour}
                                variant="outlined"
                                label="Close"
                                size='small'
                                inputProps={{
                                    name: `dayCloseTime-${idx}`,
                                }}
                                value={day.closingTime}
                                disabled={day.open == 0}
                                onChange={handleChange}
                            />
                        </Box>
                    )
                })}
            </FormGroup>
        </div>
    )
}

